import { CellProps } from '@modules/table/types/table.types';
import { Typography } from '@mui/material';
import React, { FC } from 'react';

export interface BillCellProps {
  bill: number; 
  status: boolean;
  size?: string;
}

export const BillCell:FC<CellProps<BillCellProps>> = ({
  dataCell
}) => {
  const textDecoration = dataCell.status ? 'none' : 'line-through';
  const stringNum = (dataCell.bill > 0) ? `$${dataCell.bill}` : '';

  let fontSize = '';
  switch(dataCell.size) {
  case('small'):
    fontSize = '1rem';
    break;
  case('medium'):
    fontSize = '1.2rem';
    break;
  case('large'):
    fontSize = '1.6rem';
    break;
  default:
    fontSize = '1.2rem';
  }


  return (
    <Typography
      color="primary"
      // variant='body1'
      // variant={fontSize}
      sx={{
        fontSize: {fontSize},
        textDecoration: textDecoration,
      }}
    >
      {stringNum}
    </Typography>
  );
};
