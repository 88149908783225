import { getProfileData } from '@modules/table/api/firebase';
import { 
  useCommonTableContextState, useCommonTableContextUpdater 
} from '@modules/table/context/CommonTableContext/context';
import { TableFetchParams } from '@modules/table/types/table.types';
import { Box, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ClientStatusType, ProfileType } from "@modules/table/types/user.types";
import { TypeCell } from '../Cells';
import { ScammerIconBtn } from '@modules/form/components/Buttons/ScammerIconBtn';
import { setStatus } from '@modules/table/api/firebase/setStatus';
import { HeaderMonthPanel } from './HeaderMonthPanel';
import { NavTabs } from './NavTabs';
import { BtnShiftPanel } from './BtnShiftPanel';

const ProfileHeader = () => {
  const { tableMode, id, day } = useParams<TableFetchParams>();
  const { setProfile } = useCommonTableContextUpdater();
  const { profile } = useCommonTableContextState();
  
  const [stateScamBtn, setStateScamBtn] = useState<boolean>(false);

  useEffect(() => {
    if(profile) {
      (profile.data.status === ClientStatusType.SCAMMER) ?
        setStateScamBtn(true) : setStateScamBtn(false);
    }
  }, [profile]);

  useEffect(()=> {
    if (tableMode && id) {
      getProfileData(tableMode, id).then(data => setProfile(data as ProfileType));
    }
  }, [tableMode, id, stateScamBtn]); // [stateScamBtn]

  const scamHandler = (e: any, id: string) => {
    e.preventDefault();
    if(!stateScamBtn) {
      setStatus(id, ClientStatusType.SCAMMER);
    } else {
      setStatus(id, ClientStatusType.REGULAR);
    }
    setStateScamBtn((prev) => !prev);
  };

  return (
    <Box component="div" 
      sx={{
        padding: '2rem 0rem 0rem 1rem',
        // width: '%'
      }}
    >
      <Box component="div" 
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box component="div" 
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {profile ? (
            <>
              <Typography  variant="h4" color='primary.main'
                sx={{
                  padding: '0 0.5rem 0 0',
                }}
              >
                {profile.data.name}
              </Typography>
              <TypeCell dataCell={profile.type} />
              <div style={{ marginRight: '0.5rem'}}/>

              {/* STATUS title?  */}
              <Typography  variant="button" color='secondary.main' >
                {profile.data.status === 'Scammer' ? profile.data.status : ''}
              </Typography>

              {(profile.type === 'client') ?
                (<ScammerIconBtn 
                  state={stateScamBtn}
                  onClick={(e: any) => scamHandler(e, profile.data.id)} 
                />) 
                : null
              } 
            </>
          ) : (
            <>
              <Skeleton variant="rounded" animation="wave" width='6rem' height='2rem' sx={{marginRight:'1rem'}}/>
              <Skeleton variant="rounded" animation="wave" width='4rem' height='2rem' />
            </>
          )}
        </Box> 

        {(profile && profile.type === 'operator' && !day) ? (
          <BtnShiftPanel />
        ) : null  }

        <Box 
          sx={{ 
            display: 'flex', 
            width: '30rem', 
            justifyContent: 'flex-end'
          }}
        >
          <HeaderMonthPanel />
        </Box>
      </Box>

      {(day) ? <NavTabs /> : 
        <Box sx={{ width: '100%', height: '2rem'}}/>
      }
    </Box>
  );
};

export default ProfileHeader;