import { Timestamp } from "firebase/firestore";
import { MoneyLevelByModelType, TransactionWithUsersMapType, WorkshiftEnum } from "../../types/transaction.types";
import { calcTotalAverageMax } from "../../utils/calcTotalAverageMax";
import { calculateDayNums } from "../dayData/calculateDayNums";
import { getDocsByDate } from "../firebase/getDocsByDate";
import { getDayData } from "./getDayData";
import { DocsByDayType } from "./getMonthChattersStatistic";
import { getMonthData } from "./getMonthData";
import { setLevelShare } from "./setLevelShare";

export const getMonthModelStatistic = async<Model>(params: DocsByDayType) => {
  const {tableMode, id, year, month} = params;
  const yearNum = +('20'+year);
  const { data, modelLevels: levelMonth } = await getMonthData(tableMode, id, yearNum, +month);
  
  let arr: Model[] = [];
  let arrRevenue: number[] = [];

  if(data && levelMonth) {
    for(let day = 11; day <= 30; day++ ) {
      const oneDayTrans = getDayData(data, yearNum, +month, day);
      const startDate = Timestamp.fromDate(new Date(yearNum, +month-1, day));

      if(oneDayTrans.length > 0) {
        const dayShift = oneDayTrans.filter(item => (item.workshift === WorkshiftEnum.DAY));
        const nightShift = oneDayTrans.filter(item => (item.workshift === WorkshiftEnum.NIGHT));

        const {total: totalIncome } = calculateDayNums<Model>(oneDayTrans as unknown as Model[]);
        const {total: dayShiftIncome} = calculateDayNums<Model>(dayShift as unknown as Model[]);
        const {total: nightShiftIncome} = calculateDayNums<Model>(nightShift as unknown as Model[]);
        // -- extract to seperate Wrapper function -- //

        const dayChatterLevel = setLevelShare(dayShiftIncome, levelMonth);
        const nightChatterLevel = setLevelShare(nightShiftIncome, levelMonth);

        const dayChatter = (dayShift.length > 0) ? (
          {
            chatter: {...dayShift[0].operator},
            total: dayShiftIncome,
            level: dayChatterLevel,
            profit: +(dayShiftIncome * dayChatterLevel).toFixed(2),
          }
        ) : null;

        const nightChatter = (nightShift.length > 0) ? (
          {
            chatter: {...nightShift[0].operator},
            total: nightShiftIncome,
            level: nightChatterLevel,
            profit: +(nightShiftIncome * nightChatterLevel).toFixed(2),
          }
        ) : null;

        const oneDay = {
          id: startDate,
          date: startDate,
          total: totalIncome,

          Nchatter: nightChatter?.chatter,
          NightTotal: nightChatter?.total,
          Nlevel: nightChatter?.level,
          Nprofit: nightChatter?.profit,

          Dchatter: dayChatter?.chatter,
          DayTotal: dayChatter?.total,
          Dlevel: dayChatter?.level,
          Dprofit: dayChatter?.profit,
        } as unknown as Model;
  
        arr.push(oneDay);

        arrRevenue.push(totalIncome);
      } else {
        const emptyDay = {
          id: startDate.seconds.toString(),
          date: startDate,
          total: 0,

          Nchatter: null,
          NightTotal: 0,
          Nlevel: 0,
          Nprofit: 0,

          Dchatter: null,
          DayTotal: 0,
          Dlevel: 0,
          Dprofit: 0,
        } as unknown as Model;
    
        arr.push(emptyDay);
      }
    }
  }
  const { total: totalRevenue, average: averageDay, max: maxDay } = calcTotalAverageMax(arrRevenue);
  const arrDayRows = arr;
  return { arrDayRows, totalRevenue, averageDay, maxDay };
};