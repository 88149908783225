
import React from 'react';

import { 
  doc, setDoc, 
} from 'firebase/firestore';

import { db } from '../../../../firebase/firebase';
import {  TransactionWithUsersMapType } from '@modules/table/types/transaction.types';

// Probably all Adding requests
export const editTransaction = async(
  id: string, dataTrans: Partial<TransactionWithUsersMapType>, 
) => {
  if(!dataTrans) return { error: "Data from Form doesn't provide" };
  try {
    const docRef = doc(db, 'transaction', id);
    await setDoc(docRef,dataTrans, { merge: true });

    return { docId: id };
  } catch(err) {
    return { error: err };
  }
};
