import { TransactionWithUsersMapType } from "@modules/table/types/transaction.types";

export interface FormContextDispatchType {
    handleChange: (id: keyof TransactionWithUsersMapType, value:any) => void;
    setEditData: (payload: any) => void;
    restartInitial: () => void;
}

export const InitialDispatch:FormContextDispatchType = {
  handleChange: () => null,
  setEditData: () => null,
  restartInitial: () => null,
};