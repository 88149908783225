import React, { FC } from 'react';
import { useCommonTableContextState, useCommonTableContextUpdater } from '@modules/table/context/CommonTableContext/context';
import { useFormContextDispatch, useFormContextState } from '@modules/form/context/Form/context';
import { editTransaction } from '@modules/table/api/firebase/editTransaction';
import { StatusTransactionType } from '@modules/table/types/transaction.types';
import { IconCaptionBtn } from '@modules/form/components/Buttons/IconCaptionBtn';

import {
  Replay as ReplayIcon,
  DeleteForever as DeleteForeverIcon,
  Save as SaveIcon,
  KeyboardReturn as KeyboardReturnIcon,
} from '@mui/icons-material';
import { addTransaction } from '@modules/table/api/firebase';


export const ButtonsEditForm:FC<{id: string}> = ({
  id
}) => {
  const  { editID, stateAddForm } = useCommonTableContextState();
  const  { openDeleteModal, closeEditID, closeAddForm } = useCommonTableContextUpdater();
  const { restartInitial } = useFormContextDispatch();
  const dataForm = useFormContextState();

  const refundHandler = async(e: any)  => {
    e.preventDefault(); 
    await editTransaction(id, { status: StatusTransactionType.REFUNDED});
    closeEditID();
    restartInitial();
  };

  const saveHandler = async(e: any)  => {
    e.preventDefault(); 
    editID && await editTransaction(id, dataForm) && closeEditID();
    stateAddForm && await addTransaction(dataForm) && closeAddForm();
    restartInitial();
  };

  const closeHandler = async(e: any)  => {
    e.preventDefault(); 
    closeEditID();
    closeAddForm();
    restartInitial();
  };

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end'}}>      
      {editID ? (
        <>
          <IconCaptionBtn 
            icon={<ReplayIcon fontSize="small"/>}
            caption="Refund"
            onClick={(e:any) => refundHandler(e)}
          />
          <IconCaptionBtn 
            icon={<DeleteForeverIcon fontSize="small"/>}
            caption="Delete"
            onClick={openDeleteModal}
          />
        </>
      ) : null }

      <IconCaptionBtn 
        icon={<SaveIcon fontSize="small"/>}
        caption="Save"
        onClick={(e:any) => saveHandler(e)}
      />
      <IconCaptionBtn 
        icon={<KeyboardReturnIcon fontSize="small"/>}
        caption="Cancel"
        onClick={(e:any) => closeHandler(e)}
      />
    </div>
  );
};
