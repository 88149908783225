import { 
  Box,
  TableCell,
  TableHead, 
  TableRow as MuiTableRow,
  Typography,
  useTheme, 
} from '@mui/material';
import React, { FC, useContext, useEffect } from 'react';
import { ModelWithAction, TableColumnProps } from '@modules/table/types/table.types';
import { TransColumnType } from '@modules/table/types/transaction.types';
import { useCommonTableContextState } from '@modules/table/context/CommonTableContext/context';
import { BillCell, TotalCell } from '../Cells';
import { GetHeaderCell } from '@modules/table/api/CellMap/GetHeaderCell';

export const TableHeader = <ModelColumn extends {}>({ 
  columns,
}:TableColumnProps<ModelColumn>) => {
  const theme = useTheme();
  return (
    <TableHead>
      <MuiTableRow>
        {columns.map((column) => (
          <TableCell 
            key={column.id}
            sx={{ 
              textAlign: column.textAlign || "left",
              padding: theme.spacing(2,1),
              width: column.width,
              borderBottom: '1px solid rgba(255, 255, 255, 0.258)'
            }}
          >
            <GetHeaderCell column={column} />
          </TableCell>
        ))}
      </MuiTableRow>
    </TableHead>
  );
};
