import { useParams } from "react-router-dom";

import { 
  TableColumn, 
  TableFetchParams,
} from "@modules/table/types/table.types";

import {
  Box,
  Table as MuiTable,
  TableBody,
  TableFooter as MuiTableFooter,
  TableContainer,
  useTheme,
  Tab,
  TableCell
} from "@mui/material";

import { FooterRow } from "@modules/table/components/TableFooter";
import { TableHeader } from "@modules/table/components/TableHeader";
import { TransRowMap, DayRowMap } from "@modules/table/components/TableBody";
import { ModalAlert } from '@modules/table/components/Modal/ModalAlert';

import { columnsMonthGeneric, columnsGeneric, tableSwitchColumns } from "@modules/table/api/columns";
import { TableForm } from "@modules/form/components/TableForm";
import { CommonTableContextProvider } from "@modules/table/context/CommonTableContext/context";
import { FormContextProvider, useFormContextState } from "@modules/form/context/Form/context";
import ProfileHeader from "../ProfileHeader/ProfileHeader";
import { useEffect, useState } from "react";
import { ClientStatisticType, ModelMonthColumnType, TransactionWithUsersMapType } from "@modules/table/types/transaction.types";
import { HeaderContextProvider } from "@modules/table/context/HeaderContext/context";
import { MarginRow } from "../TableBody/MarginRow";


export const Table = () => {
  const theme = useTheme();
  const { tableMode, year, month, day, shift } = useParams<TableFetchParams>();
  const newTransaction = useFormContextState();
  const [columns, setColumns] = useState<TableColumn<any>[]>([]); 
  const [TableRowMap, setTableRowMap] = useState<any | undefined>(undefined); 
  const [TableFooter, setTableFooter] = useState<any | undefined>(undefined); 
  if (!tableMode) return <>Table Router have only ['/model', '/client', '/operator'] </>;
    
  useEffect(() => {
    if(tableMode && year && month) {
      if(day) {
        const tablePersons = tableSwitchColumns[tableMode]; 
        // tablePersons: ['model', 'client'] | ['model', 'operator'] | ['client', 'operator']
        const columnsGen = columnsGeneric(tablePersons);
        setColumns(columnsGen);
        setTableRowMap(<TransRowMap columns={columnsGen} />);
        setTableFooter(<FooterRow columns={columnsGen}/>);
      }
      if(!day) {
        const columnsGen = columnsMonthGeneric(tableMode);
        setColumns(columnsGen);
        setTableRowMap(<DayRowMap columns={columnsGen} />);
        setTableFooter(undefined);
      }
    }
  }, [tableMode, year , month, day]);

  return (
    <FormContextProvider>
      <CommonTableContextProvider>
        <HeaderContextProvider>
          <Box sx={{
            position: 'relative',
            width: '75%', 
            margin: '1rem 1rem 0 1rem', 
          }}>
            <ProfileHeader />
            <TableContainer 
              sx={{ 
                backgroundColor: 'background.default',
                borderRadius: '0.25rem',
                maxHeight: 630,
              }}
            >
              <MuiTable stickyHeader>
                <TableHeader columns={columns}/>
                
                <TableBody>
                  <MarginRow />
                  {/* ROW MAP BY PARAMS PATH */}
                  {TableRowMap}
                </TableBody>
              </MuiTable>
            </TableContainer> 
            {/* change and cut off MuiTable & TableBody */}
            <MuiTable
              sx={{
                position: 'relative',
                width: '100%',
              }}
            >
              {/* <TableBody>
                {TableFooter}
              </TableBody> */}
            </MuiTable>
            {/* MODAL */}
            <ModalAlert />
          </Box>
        </HeaderContextProvider>
      </CommonTableContextProvider>
    </FormContextProvider>
  );
};
