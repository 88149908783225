import { TableRow as MuiTableRow, useTheme } from "@mui/material";
import { ModelWithId, TableRowProps } from "@modules/table/types/table.types";
import { TransCellMap } from "./TransCellMap";
import { StatusTransactionType, TransactionWithUsersMapType } from "@modules/table/types/transaction.types";
import { useCommonTableContextUpdater } from "@modules/table/context/CommonTableContext/context";


//TableRow universal, create TransactionRow  DayStatisticRow (probably the same for model/chatter/client)
export const TransRow = <Model extends ModelWithId>({
  columns,
  item,
}:TableRowProps<Model>): JSX.Element => {
  const theme = useTheme();

  // let backgroundColor = "background.paper";
  let backgroundColor = "primary.dark";
  let backgroundHover =  "background.paper";

  const itemCopy = item as unknown as TransactionWithUsersMapType;
  if(itemCopy.status === StatusTransactionType.REFUNDED) {
    backgroundColor = "background.default";
    backgroundHover = "background.default";
  }
  if(itemCopy.status === StatusTransactionType.SCAM) {
    backgroundColor = "background.default";
    backgroundHover = "background.default";
  }

  const { setEditID } = useCommonTableContextUpdater();
  const onEdit = () => {
    setEditID(item.id); 
  };

  return (
    <MuiTableRow 
      onClick={onEdit}
      sx={{
        cursor: 'pointer',
        padding: theme.spacing(4,4),
        backgroundColor: backgroundColor,
        "&:hover": {
          backgroundColor: backgroundHover
        }
      }}
    >
      <TransCellMap columns={columns} item={item}/>
    </MuiTableRow>
  );
};
