import { Timestamp } from "firebase/firestore";
import { MoneyLevelByModelType, NameIdType, TransactionWithUsersMapType, WorkshiftEnum } from "../../types/transaction.types";
import { calcTotalAverageMax } from "../../utils/calcTotalAverageMax";
import { calculateDayNums } from "../dayData/calculateDayNums";
import { getDocsByDate } from "../firebase/getDocsByDate";
import { summarize } from "../summarize";
import { getDayData } from "./getDayData";
import { getMonthData } from "./getMonthData";
import { setLevelShare } from "./setLevelShare";

export type DocsByDayType = {
    tableMode: string;
    id: string;
    year: string;
    month: string;
}

export type OneModelRevenueType = {
  model: NameIdType;
  revenue: number;
  level: number;
  profit: number;
};

export type ChatterShiftModelsType = {
  id: string;
  date: Date;

  dayModels: OneModelRevenueType[];
  dayTotal: number;

  nightModels: OneModelRevenueType[];
  nightTotal: number;
};

export const getMonthChattersStatistic = async<Model>(params: DocsByDayType) => {
  const { tableMode, id, year, month } = params;
  const yearNum = +('20'+year);
  const { data } = await getMonthData(tableMode, id, yearNum, +month);
  
  let arr: Model[] = [];
  let monthProfit: number[] = [];

  // if(data && levelMonth) {
  if(data) {
    for(let day = 12; day <= 31; day++ ) {
      const oneDayTrans = getDayData(data, yearNum, +month, day);
      const startDate = Timestamp.fromDate(new Date(yearNum, +month-1, day));

      if(oneDayTrans.length > 0) {
        const dayShift = oneDayTrans.filter(item => (item.workshift === WorkshiftEnum.DAY));
        const nightShift = oneDayTrans.filter(item => (item.workshift === WorkshiftEnum.NIGHT));

        // const {total: dayShiftIncome} = calculateDayNums<Model>(dayShift as unknown as Model[]);
        // const {total: nightShiftIncome} = calculateDayNums<Model>(nightShift as unknown as Model[]);
        // -- extract to seperate Wrapper function -- //
        

        const dayModelsID: string[] = [];
        dayShift.forEach((items) => {
          if(!dayModelsID.includes(items.model.id)) {
            dayModelsID.push(items.model.id);
          }
        });
        const nightModelsID: string[] = [];
        nightShift.forEach((items) => {
          if(!nightModelsID.includes(items.model.id)) {
            nightModelsID.push(items.model.id);
          }
        });

        

        let levelArr: any[] = [];
        // const levelFunc = () => { 
        //   dayModelsID.forEach(async (modelID) => {
        //     const levels = await getDocsByDate('level', 'model', modelID, startDateMonth, endDateMonth);
        //     levelArr.push(levels[0]);
        //   }
        //   );
        // };

        // levelFunc();
        // console.log('levelArr',levelArr);

        // if(levelArr.length > 0) {
        if(levelArr) {
          const shiftArr = (modelsIDArr: string[], shiftTrans: any[]) => {
            const shiftModelArr: OneModelRevenueType[] = [];
            const shiftProfit: number[] = [];
  
            modelsIDArr.forEach((modelID, index) => {
              const oneModelTrans: TransactionWithUsersMapType[] = shiftTrans.filter(item => item.model.id === modelID);
              const {total: revenueOneModel} = calculateDayNums<Model>(oneModelTrans as unknown as Model[]);
            
              // const oneLevel = setLevelShare(revenueOneModel, levelArr[index]);
              const oneModelRevenue = {
              // model: levelArr[index].model.name,
                model: oneModelTrans[0].model,
                revenue: revenueOneModel,
                // level: oneLevel,
                level: 0.15,
                // profit: +(revenueOneModel * oneLevel).toFixed(2),
                profit: +(revenueOneModel * 0.15).toFixed(2),
              };
              shiftModelArr.push(oneModelRevenue);
              shiftProfit.push(oneModelRevenue.profit);
            });

            const totalProfit = summarize(shiftProfit);

            return { shiftModelArr, totalProfit }; 
          };

          const {shiftModelArr: dayModelsArr, totalProfit: dayShiftProfit } = shiftArr(dayModelsID, dayShift);
          const {shiftModelArr: nightModelsArr, totalProfit: nightShiftProfit } = shiftArr(nightModelsID, nightShift);
          monthProfit.push(dayShiftProfit + nightShiftProfit);
         
          const oneDay = {
            id: startDate.toString(),
            date: startDate,

            dayModels: dayModelsArr, 
            dayTotal: null, 

            nightModels: nightModelsArr, 
            nightTotal:  null, 
          };

          arr.push(oneDay as unknown as Model);
        
        } else {
          const emptyDay = {
            id: startDate.toString(),
            date: startDate,

            dayModels: null, 
            dayTotal: null,

          } as unknown as Model;

          arr.push(emptyDay);
        }

      }
    }
  }

  
  const arrDayRows = arr; 
  const totalMonthProfit = summarize(monthProfit);
  // return {arrDayRows, totalRevenue, averageDay, maxDay};
  return { arrDayRows, totalMonthProfit };
};