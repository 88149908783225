import { ModelWithAction, TableColumn} from "@modules/table/types/table.types";
import { UserType } from '../../types/user.types';
import { 
  ClientStatisticType, 
  ModelMonthColumnType, 
  TransactionWithUsersMapType 
} from '@modules/table/types/transaction.types';

export const columnsGeneric = (
  tableMode: UserType[]
): TableColumn<TransactionWithUsersMapType & ModelWithAction>[] => {
  
  const columnsData = tableMode.map((item) => {
    const label = item.charAt(0).toUpperCase() + item.slice(1); 
    if(item === 'client') {
      return { id: item, label: label, value: item, width: '15%'};
    } 
    return { id: item, label: label, value: item, width: '10%'};
  });

  return [
    { id: "action", label: "", value: 'action', width: '2%', },
    { id: "date", label: "Date", value: "date", width: '15%', }, 
    ...columnsData,
    { id: "bill", label: "Bill", value: "bill", width: '8%', textAlign: 'right',},
    { id: "type", label: "Type", value: "type", width: '10%', },
    { id: "content", label: "Content", value: "content", width: '12%', },
    { id: "description", label: "Description", value: "description", width: '18%', },
  ];
};

export const columnsMonthGeneric = (profile: UserType) => {
  switch(profile) {
  case(UserType.CLIENT): 
    return [
      { id: "date", label: "Date", value: "date", width: '10%', }, 
      { id: "total", label: "Total", value: "total", width: '20%', },

      { id: "Dmodel", label: "Model", value: `Dmodel`, width: '10%', textAlign: 'right'},
      { id: "Dchatter", label: "Chatter", value: `Dchatter`, width: '10%', textAlign: 'center'},
      { id: "day", label: "Day", value: "DayTotal", width: '20%', textAlign: 'left' },
      
      { id: "Nmodel", label: "Model", value: `Nmodel`, width: '10%', textAlign: 'right'},
      { id: "Nchatter", label: "Chatter", value: `Nchatter`, width: '10%', textAlign: 'center'},
      { id: "night", label: "Night", value: "NightTotal", width: '10%', textAlign: 'left'},

      // { id: "average", label: "Average", value: "average", width: '5%', },
      // { id: "max", label: "Max", value: "max", width: '5%', },
    ] as TableColumn<any>[];
    // as TableColumn<ClientStatisticType>[];
    
  case(UserType.MODEL): 
    return [
      { id: "date", label: "Date", value: "date", width: '10%', }, 
      { id: "total", label: "Total", value: "total", width: '20%', },

      { id: "Dchatter", label: "Chatter", value: `Dchatter`, width: '5%', textAlign: 'right'},
      { id: "day", label: "Day", value: "DayTotal", width: '5%', textAlign: 'right' },
      { id: "Dlevel", label: "Lvl", value: "Dlevel", width: '2%', textAlign: 'center'},
      { id: "Dprofit", label: "Profit", value: "Dprofit", width: '20%', },
      
      { id: "Nchatter", label: "Chatter", value: `Nchatter`, width: '8%', textAlign: 'right'},
      { id: "night", label: "Night", value: "NightTotal", width: '5%', textAlign: 'right'},
      { id: "Nlevel", label: "Lvl", value: "Nlevel", width: '2%', textAlign: 'center'},
      { id: "Nprofit", label: "Profit", value: "Nprofit", width: '10%', },
    ] as TableColumn<any>[];
    // as TableColumn<ModelMonthColumnType>[];

  case(UserType.OPERATOR): 
    return [
      { id: "date", label: "Date", value: "date", width: '10%', }, 

      { id: "Dmodel", label: "Model", value: "Dmodel", width: '3%', textAlign: 'left' },
      { id: "Drevenue", label: "Revenue", value: "Drevenue", width: '3%', textAlign: 'right' },
      { id: "Dlevel", label: "Lvl", value: "Dlevel", width: '2%', textAlign: 'center'},
      { id: "Dprofit", label: "Profit", value: "Dprofit", width: '2%', },
      { id: "day", label: "Day Total", value: "DayTotal", width: '20%', textAlign: 'left' },
    
      { id: "Nmodel", label: "Model", value: "Nmodel", width: '3%', textAlign: 'left' },
      { id: "Nrevenue", label: "Revenue", value: "Nrevenue", width: '3%', textAlign: 'right' },
      { id: "Nlevel", label: "Lvl", value: "Nlevel", width: '2%', textAlign: 'center'},
      { id: "Nprofit", label: "Profit", value: "Nprofit", width: '2%', },
      { id: "night", label: "Night Total", value: "NightTotal", width: '10%', textAlign: 'left' },
    ] as TableColumn<any>[];
  
  default:
    return [];
  }
};