
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ModelWithId, TableColumn, TableFetchParams, TableRowMapProps } from '@modules/table/types/table.types';
import { FormRow } from '@modules/form/components/FormRow';
import { TransRow } from './TableRow';
import { useFormContextDispatch, useFormContextState } from '@modules/form/context/Form/context';
import { 
  useCommonTableContextState, 
  useCommonTableContextUpdater 
} from '@modules/table/context/CommonTableContext/context';
import { calculateDayNums } from '@modules/table/api/dayData';
import { getTransByOneDay } from '@modules/table/api/dayData';
import { TransactionWithUsersMapType, WorkshiftEnum } from '@modules/table/types/transaction.types';
import { TableCell, TableRow } from '@mui/material';
import { BtnAddRowSell } from './BtnAddRowSell';
import { TableForm } from '@modules/form/components/TableForm';
import { SkeletonRowMap } from '../SkeletonRowMap';


export const TransRowMap = <Model extends ModelWithId>({
  columns
}:TableRowMapProps<Model>) => {
  const { tableMode, id, year, month, day, shift } = useParams<TableFetchParams>();
  const { editID, stateAddForm, copyID } = useCommonTableContextState();
  const { setSumBills, closeDeleteModal } = useCommonTableContextUpdater();
  const { setEditData, } = useFormContextDispatch(); 
  const [items, setItems] = useState<Model[]  | undefined>(undefined);
  const newTransaction = useFormContextState();

  const messagesEndRef = useRef<HTMLDivElement>(null);

  // for AddSale Button 
  let isToday: boolean = false;
  const today = new Date(Date.now());
  if (month && day && today.getMonth()+1 === +month && today.getDate() === +day) isToday = true;


  useEffect(()=> {
    const fetchData = async() => {
      if (tableMode && id && year && month && day) {
        const data = await getTransByOneDay({tableMode, id, year, month, day});
        setItems(data as unknown as Model[]);

        if(shift) {
          if( shift === 'day') {
            const dayTrans = data.filter(item => item.workshift === WorkshiftEnum.DAY);
            setItems(dayTrans as unknown as Model[]);
          }
          if( shift === 'night') {
            const nightTrans = data.filter(item => item.workshift === WorkshiftEnum.NIGHT);
            setItems(nightTrans as unknown as Model[]);
          }
        }
      }
    };
    fetchData();
  }, [tableMode, id, year, month, day, shift, stateAddForm, editID, closeDeleteModal]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if(items) {
      const {total: totalIncome} = calculateDayNums<Model>(items);
      setSumBills(totalIncome);
    } 
    scrollToBottom();
  }, [items]);

  useEffect(() => {
    const getCopyData = () => {
      if (items && copyID) {
        const copyItem = items.filter((item) => item.id === copyID);
        setEditData(copyItem[0]);
      }
    };
    getCopyData();

    const getEditData = () => {
      if (items && editID) {
        const copyItem = items.filter((item) => item.id === copyID);
        setEditData(copyItem[0]);
      }
    };
    getEditData();
  }, [copyID, editID]);

  // emptyRows
  const [rowsPerPage, setRowsPerPage] = useState(5); 
  const rows:Model[] = items ? items : [];
  const emptyRows = Math.max(0, rowsPerPage - rows.length);

  return (
    <>
      {items ? (
        items.map((item: Model) => {
          if (editID && editID === item.id) {
            return  (<FormRow key={item.id} item={item} columns={columns} />);
          }
          else return (<TransRow key={item.id} item={item} columns={columns} />);
        })
      ) : (
        <SkeletonRowMap length={5} columns={columns}/>
      )}
      {(isToday) ? (
        (!stateAddForm) ? <BtnAddRowSell /> : (
          <TableForm 
            columns={columns as unknown as TableColumn<TransactionWithUsersMapType>[]} 
            item={newTransaction}
          />
        )
      ) : null }
      {/* #yakor ref={messagesEndRef}  */}
      <TableRow 
        sx={{ 
          height: '1rem', 
          backgroundColor: "#011028",
        }}>
        <TableCell ref={messagesEndRef}  sx={{borderBottom: 'none', padding: 0}} colSpan={8} />
      </TableRow>
      {(items && emptyRows > 0) ? (
        <>
          <TableRow 
            sx={{ 
              height: 74 * emptyRows, 
              backgroundColor: "#011028",
            }}>
            <TableCell sx={{borderBottom: 'none'}} colSpan={8} />
          </TableRow>
        </>
      ) : (
        null
      )}
    </>
  );
};
