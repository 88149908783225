import { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import { PaletteMode, ThemeProvider } from "@mui/material";
import { darkTheme, lightTheme } from "./theme";

import { navigationRoutes } from "./navigationRoutes";
import TableRouter from "@modules/table/components/Routing/TableRouter";

function App() {
  const [theme, setTheme] = useState(darkTheme);
  // replace with ref ?
  document.body.style.background = theme.palette.background.default;

  const toggleTheme = (mode: PaletteMode) => {
    if (mode === "light") {
      setTheme(lightTheme);
    } else {
      setTheme(darkTheme);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          {/* {Object.values(navigationRoutes).map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))} */}
          <Route path='/table/*' element={<TableRouter />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
