import { IconButton, Typography } from '@mui/material';
import React, { FC } from 'react';
import { IconCaptionBtnProps } from '@modules/table/types/button.types';

export const IconCaptionBtn:FC<IconCaptionBtnProps> = ({
  icon,
  caption,
  onClick,
}) => (
  <IconButton 
    aria-label="save" 
    size="small" 
    color='primary' 
    onClick={onClick}
  >
    {icon}
    <Typography variant='subtitle1'
      sx={{ marginLeft: '0.25rem', marginRight: '1rem' }}
    >
      {caption}
    </Typography>
  </IconButton>
);
