import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { InputProps } from '@modules/form/types/form.types';
import { ContentType } from '@modules/table/types/transaction.types';

export const InputSelect:FC<InputProps> = ({
  id,
  label,
  value,
  handleChange
}) => {
  const [localValue, setLocalValue] = useState<string>(ContentType.CUSTOM);

  useEffect(() => {
    if(value) {
      setLocalValue(value);
    }
  }, []);

  return (
    <FormControl 
      sx={{ 
        m: 0.25, 
        minWidth: 100,
        borderColor: 'primary.main',
      }} 
      size="small" 
    >
      <InputLabel id="demo-select-small">{label}</InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={localValue}
        label={label}
        onChange={(e) => {
          handleChange(id, e.target.value); 
          setLocalValue(e.target.value);
        }}
        sx={{
          borderRadius: '2rem',
          color: 'primary.main',
          fontSize: '0.8rem',
        }}
      >
        {(Object.keys(ContentType) as Array<keyof typeof ContentType>)
          .map((key) => 
            <MenuItem key={`menu-item-${key}`} value={ContentType[key]}>{ContentType[key]}</MenuItem>
          )}
      </Select>
    </FormControl>
  );
};
