import React, { FC, useContext } from 'react';
import { BtnMiddle } from '../BtnMiddle';
import { 
  AddCircle as AddCircleIcon,
  Brightness5 as Brightness5Icon,
  Bedtime as  BedtimeIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import { WorkshiftEnum } from '@modules/table/types/transaction.types';

export enum ColorType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary'
}

export interface BtnStartShiftProps {
  shift: WorkshiftEnum;
  onClick: any;
}

export const BtnStartShift: FC<BtnStartShiftProps> = ({
  shift,
  onClick
}) => {
  let icon = <></>;
  let caption = '';
  let color = ColorType.PRIMARY;

  switch(shift) {
  case(WorkshiftEnum.DAY):
    icon = <Brightness5Icon />;
    caption = 'DAY';
    break;
  case(WorkshiftEnum.NIGHT):
    icon = <BedtimeIcon />;
    caption = 'NIGHT';
    color = ColorType.SECONDARY;
    break;
  }

  return (
    <BtnMiddle 
      variant='outlined'
      color={color}
      width='100%'
      icon={<></>} 
      title={'Start '+caption}  //maybe Start Day {Date.now().getDate() getMonth()}
      onClick={onClick}
    />
  );
};