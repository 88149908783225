export enum UserType  {
    CLIENT = 'client',
    MODEL = 'model',
    OPERATOR = 'operator', 
}

export enum ClientStatusType {
    SCAMMER = 'Scammer',
    REGULAR = 'Regular',

    PRIME = 'Big',
    MIDDLE = 'Middle',
    ECONOM = 'Small'
}

export type ProfileShortType = {
    id: string;
    name: string;
    link?: string;
}
export type ProfileLinkType = {
    type: UserType;
    data: ProfileShortType;
}

export type StatusType = {
    status: ClientStatusType;
}

export type ProfileType = {
    type: UserType;
    data: ProfileShortType & StatusType;
}


// export interface ProfileType<Model>  {
//     type: keyof Model;
//     data: Model[keyof Model];
// }