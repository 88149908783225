import { TableCell, TableRow } from '@mui/material';
import React from 'react';

export const MarginRow = () => (
  <TableRow>
    <TableCell colSpan={12} sx={{ 
      height: '1rem',
      backgroundColor:'#011028',
      padding: 0,  
      border: 'none',
    }}
    />
  </TableRow>
);
