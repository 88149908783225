import { MoneyLevelByModelType, TransactionWithUsersMapType } from "@modules/table/types/transaction.types";
import { getDocsByDate } from "../firebase/getDocsByDate";

export const getMonthData = async(
  tableMode:string, id:string, 
  yearNum:number, month:number
) => {
  const startDateMonth = new Date(yearNum, +month-1, 1);
  const endDateMonth = new Date(yearNum, +month-1, 30);
  const data: TransactionWithUsersMapType[] = 
      await getDocsByDate('transaction', tableMode, id, startDateMonth, endDateMonth);

  let modelLevels: any;
  const levels: MoneyLevelByModelType[] = 
    await getDocsByDate('level', 'model', id, startDateMonth, endDateMonth);
  if (levels)  modelLevels = levels[0];

  return {data, modelLevels};
};