import { FC, useContext, useEffect, useState } from "react";
import { BillCell } from "@modules/table/components/Cells/BillCell";
import {  TableFooterCellProps } from "@modules/table/types/table.types";
import { BtnMiddle } from "@modules/table/components/Buttons/BtnMiddle";
import BtnAddSave from "@modules/table/components/Buttons/BtnAddSave/BtnAddSave";
import { 
  useCommonTableContextState, useCommonTableContextUpdater 
} from "@modules/table/context/CommonTableContext/context";
import { addTransaction } from "../firebase";
import { useFormContextState } from "@modules/form/context/Form/context";
import { TotalCell } from "@modules/table/components/Cells";
import { Typography } from "@mui/material";

// GetCellComponentBy
export const GetHeaderCell = <Model extends {}>({
  column
}:TableFooterCellProps<Model>) => {
  const { sumBills } = useCommonTableContextState();
  
  if (column.value === 'bill') {
    return (
      <TotalCell total={sumBills} />
    );
  }

  if (['DayTotal', 'NightTotal'].includes(column.value as string)) {
    return (
      <Typography fontSize='1.2rem' color='primary.main'>
        {column.label}
      </Typography>
    );
  }

  return <>{column.label}</>;
};