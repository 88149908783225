import { 
  collection, getDocs, 
} from 'firebase/firestore';

import { db } from '../../../../firebase/firebase';
import { UserType } from '@modules/table/types/transaction.types';

export const getAutoCompleteList = async(
//   list: UserType,
  list: string,
) => {
  try {
    const querySnapshot = await getDocs(collection(db, list));
    let arr:any = [];
    querySnapshot.forEach((doc) => {
      arr.push({ 
        id: doc.id, 
        ...doc.data()
      });
    });
    return arr;
  } catch(err) {
    return { error: err };
  }
};
