import { useHeaderContextState } from '@modules/table/context/HeaderContext/context';
import { getMonthName } from '@modules/table/utils/getMonthName';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';

export const HeaderMonthPanel = () => {
  const { stat } = useHeaderContextState();
  const { month, day } = useParams();
  const monthName = month ? getMonthName(+month) : '';

  if(day) return null;
  return (
    <Box        
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end'
      }}
    >
      <Typography  variant="h6" color='secondary.main' sx={{marginRight: '1rem'}}>
        {monthName} 
      </Typography>
      <Typography variant="h4" color='secondary.main' sx={{marginRight: '1rem'}}>
        ${stat?.totalRevenue} 
      </Typography>
      <Box        
        sx={{
          paddingTop: '0.25rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          justifyContent: 'flex-end'
        }}
      >
        {(stat && stat.averageDay && stat.maxDay) ? (
          <>
            <Typography  variant="subtitle2" color='primary' sx={{marginRight: '1rem'}}>
              Average Day: ${stat?.averageDay}
            </Typography>
            <Typography  variant="subtitle2" color='primary' sx={{marginRight: '1rem'}}>
              Max Day: ${stat?.maxDay}
            </Typography>
          </>
        ) : null }
      </Box>
    </Box>
  );
};
