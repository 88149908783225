import { IconBtnProps } from '@modules/table/types/button.types';
import { IconButton } from '@mui/material';
import React, { FC } from 'react';
import {
  DriveFileRenameOutlineOutlined as  EditOutlinedIcon,
} from '@mui/icons-material';


export const EditIconBtn:FC<IconBtnProps> = ({
  onClick,
}) => (
  <IconButton 
    aria-label="save" 
    size="small" 
    color='primary' 
    onClick={onClick}
  >
    <EditOutlinedIcon fontSize="small" />
  </IconButton>
);
