import { TableColumn } from '@modules/table/types/table.types';
import { UserType } from '@modules/table/types/transaction.types';
import React from 'react';
import { GetChatterDayRowCell } from './GetChatterDayRowCell';
import { GetDayRowCell } from './GetDayRowCell';

export const SwitchDayRow = (
  tableMode: string | undefined, 
  column: TableColumn<any>, 
  item: any, 
  link: string
) => {
  switch(tableMode) {
  case UserType.MODEL:
    return <GetDayRowCell column={column} item={item} link={link}/> ;
  case UserType.CLIENT:
    return <GetDayRowCell column={column} item={item} link={link}/> ;
  case UserType.OPERATOR:
    return <GetChatterDayRowCell column={column} item={item} link={link}/>;
  default:
    return null;
  }
};
