import React from 'react';
import { TableCell, TableRow as MuiTableRow, useTheme } from "@mui/material";
import { ButtonsEditForm } from '../ButtonsEditForm';

export const ButtonsRowInForm = ({
  itemId
}: {itemId:string}) => {
  const theme = useTheme();
  return (
    <MuiTableRow
      sx={{
        backgroundColor: "background.default",
      }}
    >
      <TableCell colSpan={9} 
        sx={{
          padding: theme.spacing(0.5,0),
          border: 'none',
        }}
      >
        <ButtonsEditForm id={itemId} />
      </TableCell>
    </MuiTableRow>
  );
};
