import React, { 
  createContext, FC, useContext, useMemo, useReducer, 
} from 'react';
import { 
  TransactionWithUsersMapType 
} from '@modules/table/types/transaction.types';
// import { editTransaction, getTransactionsByID } from '@modules/table/service/transaction.service';
import { InitialState } from './InitialState';
import { FormContextDispatchType, InitialDispatch } from './InitialDispatch';
import { ChildrenProps } from '@modules/table/types/table.types';
import formReducer from './reducer';
import { actions } from './actions';


export const FormContextState = createContext<TransactionWithUsersMapType>(InitialState);
export const FormContextDispatch = createContext<FormContextDispatchType>(InitialDispatch);

export const FormContextProvider:FC<ChildrenProps> = ({ children }) => {
  // whare paste InitialDispatch into useReducer ?
  const [state, dispatch] = useReducer(formReducer, InitialState);

  const api = useMemo<FormContextDispatchType>(
    () => ({ 
      handleChange: (id: keyof TransactionWithUsersMapType, value:any) =>
        dispatch({ type: actions.ADD_FIELD, payload: { id, value } }),
      setEditData: (payload: any) => 
        dispatch({ type: actions.SET_EDIT_DATA, payload: payload }),
      restartInitial: () => 
        dispatch({ type: actions.INITIAL_DATA }),
    }),
    [dispatch]
  );

  return (   
    <FormContextState.Provider value={state}>
      <FormContextDispatch.Provider value={api}>
        {children}
      </FormContextDispatch.Provider>
    </FormContextState.Provider>
  );
};

export const useFormContextState = () => {
  const context = useContext(FormContextState);
  if (context === undefined) {
    throw new Error('useFormContextState must be used within FormContextProvider');
  }
  return context;
};

export const useFormContextDispatch = () => {
  const context = useContext(FormContextDispatch);
  if (context === undefined) {
    throw new Error('useFormContextDispatch must be used within FormContextProvider');
  }
  return context;
};