
import { 
  BillCell,
  DateCell,
  DateEnumType,
  LinkWrapperCell,
  TypeCell
} from "@modules/table/components/Cells";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { DayCellProps, ModelWithId, TableCellProps } from "../../types/table.types";
import { ChatterShift, ClientStatisticType, NameIdType, WorkshiftEnum } from "../../types/transaction.types";



export const GetDayRowCell = <Model extends ModelWithId>({
  column,
  item,
  link
}:DayCellProps<Model>) => {

  if (column.value === 'date') {
    const dateObj = {
      date: +item[column.value],
      mode: DateEnumType.DAY,
    };
    return (
      <div style={{ margin: '0 0 0 1rem'}}>
        <DateCell dataCell={dateObj} />
      </div>
    );
  }

  if (['night', 'day'].includes(column.value as string)) {
    const workshiftData = item[column.value] as unknown as ChatterShift;
    if(workshiftData === null) return null;
    return (
      <Link 
        onClick={e => e.stopPropagation()} 
        to={`${link}/${column.value as string}`}
      >
        <p>${workshiftData.total} - {workshiftData.chatter.name}</p>
      </Link>
    );
  }

  if (['total', 'average', 'max', 'DayTotal', 'NightTotal', ].includes(column.value as string)) {
    let statusBill: boolean = true;
    const Cell = (
      <BillCell 
        dataCell={{
          bill: +item[column.value],
          status: statusBill,
        }} 
      />
    );

    if (column.value === 'NightTotal') return LinkWrapperCell(link, Cell, WorkshiftEnum.NIGHT);
    if (column.value === 'DayTotal') return LinkWrapperCell(link, Cell, WorkshiftEnum.DAY);
    return Cell;
  }

  if (['Nprofit', 'Dprofit'].includes(column.value as string)) {
    if(!item[column.value] || +item[column.value] === 0) return null;
    return (
      <>
        <Typography variant="body1">
          {`$${item[column.value]}`}
        </Typography>
      </>
    );
  }

  if (['Nchatter', 'Dchatter', 'Nmodel', 'Dmodel'].includes(column.value as string)) {
    const chatter = item[column.value] as unknown as NameIdType;
    const model = item[column.value] as unknown as NameIdType;
    if(!chatter || chatter === null) return null;
    if(!model || model === null) return null;
    if (column.value === 'Nchatter') return LinkWrapperCell(link, chatter.name, WorkshiftEnum.NIGHT);
    if (column.value === 'Dchatter') return LinkWrapperCell(link,chatter.name, WorkshiftEnum.DAY);
    if (column.value === 'Nmodel') return LinkWrapperCell(link, model.name, WorkshiftEnum.NIGHT);
    if (column.value === 'Dmodel') return LinkWrapperCell(link, model.name, WorkshiftEnum.DAY);
  }

  if (['Nlevel', 'Dlevel'].includes(column.value as string)) {
    if (!item[column.value] || +item[column.value] === 0) return null;
    // return (
    //   <>{item[column.value]}</>      
    // );
    return <TypeCell dataCell={String(item[column.value])} /> ;
  }
  return null;
};