export const getDateTime = (dateString:string) => {
  if (dateString === '') return '';

  const date: Date = new Date (dateString);
  const setZeroBeforeDate = (num: number) => {
    let formattedNumber = num.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    });
    return formattedNumber;
  };

  const monthString = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

  const dateArr:number[] = [
    date.getDate(), 
    date.getMonth(), 
    date.getHours(), 
    date.getMinutes()
  ];

  const newDateArr = dateArr.map((datePiece) => setZeroBeforeDate(datePiece));
  const dateFormatted = `${newDateArr[0]} ${monthString[+newDateArr[1]]} ${newDateArr[2]}:${newDateArr[3]}`;
  return dateFormatted;
};
