import { Box } from '@mui/material';
import React from 'react';
import { BillCell } from '../BillCell';

export const TotalCell = ({
  total
}:{total: number}) => (
  <Box
    sx={{
      display: 'flex', 
      justifyContent: 'flex-end', 
      alignItems: 'center',
      color: 'primary.main'
    }}
  > 
    Total:
    <span style={{ marginRight: '0.5rem'}}/>
    <BillCell dataCell={{ bill: total, status: true, size: 'large' }} />
  </Box>
);
