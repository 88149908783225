import React, { useEffect, useState } from 'react';
import { ModelWithId, TableCellProps} from '@modules/table/types/table.types';

import { 
  InputAutoComplete, 
  InputIconSymbol, 
  InputSelect,
  InputText,
} from '@modules/form/components/Inputs';
import { 
  NameIdType,
  TransactionWithUsersMapType,
} from '@modules/table/types/transaction.types';
import { getAutoCompleteList } from '@modules/table/api/firebase/getAutoCompleteList';
import { DateCell, DateEnumType } from '@modules/table/components/Cells';
import { useFormContextDispatch } from '@modules/form/context/Form/context';


// export type CellComponentMapProps<Model> = {
//   [key in keyof Model]: React.FC<CellProps<any>>;  
// }

export type AutoCompleteListType<Model> = {
  // <Partial<TransactionWithUsersMapType>> 
  // [key in keyof Model]: string[];
  [key in keyof Model]: NameIdType[];
}

// export const PromptInput:FC<TableCellProps<TransactionWithUsersMapType>>  = ({
export const PromptInput = <Model extends ModelWithId>({
  item,
  column,
}: TableCellProps<Model>) => {
  const  { handleChange }  = useFormContextDispatch();

  // const copyInputs = useFormContextState();
  // console.log('PromptInput', copyInputs);

  const [autoCompleteList, setAutoCompleteList] = 
    useState<AutoCompleteListType<Partial<TransactionWithUsersMapType>>>({
    // [UserType.MODEL]: [],
    // [UserType.CLIENT]: [],
    // [UserType.OPERATOR]: [],
      model: [{
        id: '',
        name: '',
      }],
      client: [{
        id: '',
        name: '',
      }],
      operator: [{
        id: '',
        name: '',
      }],
    });

  // hook 
  useEffect(()=> {
    // TRANSFER TO TABLE PAGE - USE hook or context? 
    const fetchList = () => {
      // UserType mapping
      ['model', 'client', 'operator'].forEach(async(listName) => {
        const data = await getAutoCompleteList(listName);
        if(data) setAutoCompleteList((prev) => ({...prev, [listName]: [...data]}));
      });
    };
    fetchList();
  }, []);

  // if (column.value === 'action') {
  //   return <ActionBtnInOpenForm id={item.id}/>;
  // }
  if (column.value === 'action') {
    return (
      <div style={{
        width: '1.5rem'
      }}></div>
    );
  }
  if (column.value === 'date') {
    const dateObj = {
      date: +item[column.value],
      mode: DateEnumType.DATE,
    };
    return (
      <div style={{ margin: '0 0 0 1rem'}}>
        <DateCell dataCell={dateObj} />
      </div>
    );
  }
  
  switch(column.value) {
  case('model'): 
    if(autoCompleteList.model) {//Change Type <AutoCompleteListType<Partial<TransactionWithUsersMapType>>> 
      return InputAutoComplete(autoCompleteList.model, column.id, handleChange, item[column.value]);
    } else return null;
  case('client'): 
    if(autoCompleteList.client) {
      // console.log('autoCompleteList.client', item[column.value]);
      return InputAutoComplete(autoCompleteList.client, column.id, handleChange, item[column.value]);
    }  else return null;
  case('operator'): 
    if(autoCompleteList.operator) {
      return InputAutoComplete(autoCompleteList.operator, column.id, handleChange, item[column.value]);
    }  else return null;
  }
  if (column.value === 'bill') {
    return (
      <InputIconSymbol 
        id={column.id}
        symbol='$' 
        label="Bill" 
        value={item[column.value]}
        handleChange={handleChange}
      />
    );
  }
  if (column.value === 'type') {
    return (
      <InputSelect 
        id={column.id}
        label={column.id}
        value={item[column.value]}
        handleChange={handleChange}
      />
    );
  }
  if (column.value === 'content' || column.value === 'description') {
    return (
      <InputText 
        key={`input-${column.label}`} 
        id={column.id}
        label={column.label} 
        value={item[column.value]}
        handleChange={handleChange}
      />
    );
  }

  return null;
};
