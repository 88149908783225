import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { NameOptionType } from '@modules/form/types/form.types';
import { useEffect, useState } from 'react';
import { useCommonTableContextState } from '@modules/table/context/CommonTableContext/context';

const filter = createFilterOptions<NameOptionType>();

export const InputAutoComplete = (
  optionList: NameOptionType[],
  id: string,
  handleChangeAuto: any,
  dataEdit?: NameOptionType | any,
) => {
  const { copyID } = useCommonTableContextState();
  const [value, setValue] = useState<NameOptionType | null>(null);

  useEffect(() => {
    if(dataEdit) {
      setValue(dataEdit);
    }
  }, [dataEdit, copyID]);

  useEffect(() => {
    handleChangeAuto(id, value);
  }, [value]);

  return (
    <Autocomplete
      value={value}
      size="small"
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setValue({
            name: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setValue({
            name: newValue.inputValue,
          });
        } else {
          setValue(newValue);
        }
      }}
      filterOptions={
        (options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.name);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              name: `Add "${inputValue}"`,
            });
          }

          return filtered;
        } 
      }
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={optionList} 
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.name;
      }}
      renderOption={
        (props, option) => 
          <li {...props} style={{ fontSize: '0.8rem'}}>{option.name}</li>
      }
      sx={{ width: '200' }}
      freeSolo
      renderInput={(params) => (
        <TextField variant="standard" {...params} label={id}/>
      )}
    />
  );
};
