import React, { FC, ReactElement, useContext, useEffect} from 'react';
import { TableRowProps } from '@modules/table/types/table.types';
import { FormRow } from '@modules/form/components/FormRow';
import { TransactionType, TransactionWithUsersMapType, TransColumnType } from '@modules/table/types/transaction.types';
import { useCommonTableContextState } from '@modules/table/context/CommonTableContext/context';
import { useFormContextDispatch, useFormContextState } from '@modules/form/context/Form/context';
import { TableCell, TableRow as MuiTableRow, useTheme } from "@mui/material";
import { ButtonsEditForm } from '../FormRow/ButtonsEditForm';

export const TableForm:FC<TableRowProps<TransactionWithUsersMapType>> = ({
  item,
  columns,
}) => {
  const { stateAddForm } = useCommonTableContextState();
  const defaultData = useFormContextState();

  if(!stateAddForm) return null;
  
  return(
    <>
      <FormRow
        key={item.id} 
        columns={columns} 
        item={defaultData}
      />
    </>
  );
};

export default TableForm;