

export type basicMonthStatistic = {
  totalRevenue: number;
  averageDay: number;
  maxDay: number;
}

export interface InitialStateType {
    // profile: ProfileType | undefined;
    stat: basicMonthStatistic | undefined;
}

export const InitialState:InitialStateType = {
  // profile: undefined,
  stat: undefined,
};

