import { ModelWithId, TableFooterProps } from '@modules/table/types/table.types';
import { useTheme, TableRow as MuiTableRow, } from '@mui/material';
import { FooterCellMap } from '../FooterCellMap';

export const FooterRow = <Model extends ModelWithId>({
  columns,
}:TableFooterProps<Model>): JSX.Element => {
  const theme = useTheme();
  return (
    <>
      <MuiTableRow sx={{
        padding: theme.spacing(4,4),
      }}>
        <FooterCellMap columns={columns} />
      </MuiTableRow>
    </>
  );
};