import { GetChatterDayRowCell } from '@modules/table/api/CellMap/GetChatterDayRowCell';
import { GetDayRowCell } from '@modules/table/api/CellMap/GetDayRowCell';
import { SwitchDayRow } from '@modules/table/api/CellMap/SwitchDayRow';
import { DayCellMapProps, ModelWithId, TableColumn, TableRowProps } from '@modules/table/types/table.types';
import { UserType } from '@modules/table/types/user.types';
import { TableCell, useTheme } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';

export const DayCellMap = <Model extends ModelWithId>({
  columns,
  item,
  link
}:DayCellMapProps<Model>): JSX.Element => {
  const theme = useTheme();
  const { tableMode } = useParams();

  return (
    <>
      {columns.map((column, index) => (
        <TableCell
          key={`${item.id}-${column.label}-${index}`} 
          sx={{ 
            textAlign: column.textAlign || "left",
            padding: theme.spacing(2,0.5),
            width: column.width, 
            minWidth: column.width, 
          }}
        > 
          {SwitchDayRow(tableMode, column, item,link)}
        </TableCell>
      ))}
    </>
  );
};