import React from 'react';

import { 
  doc, collection, query, where, getDocs, orderBy, Timestamp, 
} from 'firebase/firestore';

import { db } from '../../../../firebase/firebase';

export const getDocsByDate = async(
  collectionName: string,
  tableMode: string, 
  id: string,
  startDate: Date, 
  endDate: Date,
) => {
  if(collection && id) {
    try {
      const q = query(
        collection(db, collectionName), 
        where(`${tableMode}.id`, "==", id),
        where('date', '>=', startDate), 
        where('date', '<', endDate)
      );
      const querySnapshot = await getDocs(q); 

      let arr:any = [];
      querySnapshot.forEach((doc) => {
        arr.push({
          ...doc.data(),     
          id: doc.id, 
        });
      });

      // getData. transfer this orderByDate in TableRowMap
      // arr.sort(
      //   (a:TransactionWithUsersMapType, b:TransactionWithUsersMapType) =>
      //     +a.date - +b.date
      // );
      return arr;
    } catch(err) {
      return { error: err };
    }
  } else {
    return { error: 'request is undefined' };
  }
};
