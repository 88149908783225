import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BtnStartShift } from '../../Buttons/BtnStartShift';
import { WorkshiftEnum } from '@modules/table/types/transaction.types';
import { useCommonTableContextState } from '@modules/table/context/CommonTableContext/context';
import { BillCell } from '../../Cells';

interface LinkTabProps {
  label?: string;
  href?: string;
}

function LinkTab(props: LinkTabProps) {
  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

export const NavTabs = () => {
  const { shift } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState('month');


  useEffect(() => {
    (shift) && setValue(shift);
  }, [shift]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    let navLink:string;
    navLink = pathname.split('/').slice(0,-1).join('/')+'/'+newValue;
    if(newValue === 'month') navLink = pathname.split('/').slice(0,-2).join('/');
    navigate(navLink);
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'flex-end'}}>
      <Tabs value={value} onChange={handleChange} aria-label="nav tabs example">
        <Tab label="Month" value='month'/>
        <Tab label="Day" value='day'/>
        <Tab label="Night" value='night' />
      </Tabs>
    </Box>
  );
};
