import { InitialStateType } from "./InitialState";
import { ActionType,  actions } from "./actions";

const Reducer = (state: InitialStateType, action: ActionType):InitialStateType => {
  switch (action.type) {
  case actions.SET_MONTH_STATISTIC:
    return {
      ...state,
      stat: { ...action.payload },
    };
  default:
    return state;
  }
}; 

export default Reducer;