import { TransactionWithUsersMapType } from "@modules/table/types/transaction.types";
import { ActionType, actions } from "./actions";
import { InitialState } from "./InitialState";

export const formReducer = (state: TransactionWithUsersMapType, action: ActionType):TransactionWithUsersMapType => {
  switch (action.type) {
  case actions.ADD_FIELD:
    if (action.payload.id === 'bill') {
      return {
        ...state,
        [action.payload.id]: +action.payload.value,
      };
    } else {
      // action.payload.value check typeof ?  ===
      return {
        ...state,
        [action.payload.id]: action.payload.value,
      };
    }
  case actions.SET_EDIT_DATA:
    // console.log('action.payload', action.payload);
    return {
      ...action.payload,
    };
  case actions.INITIAL_DATA:
    return {
      ...InitialState
    };
  default:
    return state;
  }
}; 

export default formReducer;