import { Link } from 'react-router-dom';
import { WorkshiftEnum } from '@modules/table/types/transaction.types';

export const LinkWrapperCell = (link: string, content: any, shift: WorkshiftEnum) => (
  <Link
    onClick={e => e.stopPropagation()} 
    to={`${link}/${shift}`}
  >
    {content}
  </Link>
);