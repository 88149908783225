import { CommonStateType } from "./InitialState";
import { ActionType,  actions } from "./actions";

const commonReducer = (state: CommonStateType, action: ActionType):CommonStateType => {
  switch (action.type) {
  case actions.OPEN_ADD_FORM:
    return {
      ...state,
      stateAddForm: true,
      editID: undefined,
    };
  case actions.CLOSE_ADD_FORM:
    return {
      ...state,
      stateAddForm: false,
      copyID: undefined,
    };
  case actions.OPEN_DELETE_MODAL:
    return {
      ...state,
      stateDeleteModal: true,
    };
  case actions.CLOSE_DELETE_MODAL:
    return {
      ...state,
      stateDeleteModal: false,
    };
  case actions.SET_EDIT_ID:
    return {
      ...state,
      editID: action.payload,
      copyID: undefined,
      stateAddForm: false,
    };
  case actions.UNDEFINE_EDIT_ID:
    return {
      ...state,
      editID: undefined,
    };
  case actions.SET_COPY_ID:
    return {
      ...state,
      copyID: action.payload,
      editID: undefined,
      stateAddForm: true,
    };
  case actions.SET_SUM_BILLS:
    return {
      ...state,
      sumBills: action.payload,
    };
  case actions.SET_PROFILE:
    return {
      ...state,
      profile: action.payload,
    };
  default:
    return state;
  }
}; 

export default commonReducer;