import { FC } from 'react';
import { EditIconBtn } from '@modules/table/components/Buttons/EditIconBtn';
import { CopyIconBtn } from '@modules/table/components/Buttons/CopyIconBtn';
import { useCommonTableContextUpdater } from '@modules/table/context/CommonTableContext/context';

export const ActionBtnInRow:FC<{id:string}> = ({
  id
}) => {
  const { setEditID, setCopyID } = useCommonTableContextUpdater();
  const onEdit = () => {
    setEditID(id); 
  };
  // const onCopy = () => {
  //   setCopyID(id); 
  // };

  return (
    <>      
      <EditIconBtn onClick={onEdit} />
      {/* <CopyIconBtn onClick={onCopy} /> */}
    </>
  );
};
