import { CellProps } from '@modules/table/types/table.types';

import React, { FC } from 'react';
import { getDateTime } from "@modules/table/utils/getDateTime";
import { Typography } from '@mui/material';

export enum DateEnumType  {
  DATE = 'date',
  DAY = 'day',
  MONTH = 'month',
}

export interface DateProps {
  date: number;
  mode: DateEnumType;
}

export const DateCell:FC<CellProps<DateProps>> = ({
  dataCell
}) => {
  const date = new Date(dataCell.date * 1000);

  const getDateString = (dateType: DateEnumType, date: Date) => {
    switch(dateType) {
    case(DateEnumType.DATE):
      return getDateTime(String(date));
    case(DateEnumType.DAY):
      return getDateTime(String(date)).split(' ').slice(0, -1).join(' ');
    case(DateEnumType.MONTH):
      return getDateTime(String(date)).split(' ').slice(1, -1).join(' ');
    default:
      return getDateTime(String(date));
    }
  };

  return (
    <Typography variant='body2' color='text.primary'>
      {getDateString(dataCell.mode, date)}
    </Typography>
  );

};