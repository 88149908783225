import { Box, TableCell, TableRow as MuiTableRow, useTheme } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { ModelWithId, TableFetchParams, TableRowProps } from "@modules/table/types/table.types";
import { PromptInput } from "@modules/form/components/PromptInput";
import {  TransactionWithUsersMapType } from "@modules/table/types/transaction.types";
import { useParams } from "react-router-dom";
import { useFormContextDispatch, useFormContextState } from "@modules/form/context/Form/context";
import { getProfileData } from "@modules/table/api/firebase";
import { ProfileType } from "@modules/table/types/user.types";
import { 
  useCommonTableContextState, 
  useCommonTableContextUpdater 
} from "@modules/table/context/CommonTableContext/context";
import { ButtonsEditForm } from "./ButtonsEditForm";
import { ButtonsRowInForm } from "./ButtonsRowInForm";

export const FormRow = <Model extends ModelWithId>({
  item,
  columns,
}: TableRowProps<Model>): JSX.Element => {
  const theme = useTheme();
  const { shift } = useParams();
  const { profile } = useCommonTableContextState();
  const { handleChange } = useFormContextDispatch();

  useEffect(() => {
    if(profile) {
      handleChange(profile.type, {
        id: profile.data.id,
        name: profile.data.name
      });
    }
  }, [profile]);

  useEffect(() => {
    if(shift) {
      handleChange('workshift', shift);
    }
  }, [shift]);




  return (
    <>
      <MuiTableRow 
        sx={{
          padding: theme.spacing(4,4),
          backgroundColor: "primary.dark", 
          border: '1px solid white'
        }}
      >
        {columns.map((column) => (
          <TableCell 
            key={`${item.id}-${column.label}`} 
            sx={{ 
              textAlign: column.textAlign || "left",
              padding: theme.spacing(0.5,1),
              width: column.width, 
              minWidth: column.width, 
              border: 'none'
            }}
          > 
            {<PromptInput column={column} item={item} />}
          </TableCell>
        ))}
      </MuiTableRow>
      {/* {editID ? ButtonsComponent : null} */}
      <ButtonsRowInForm itemId={item.id}/>
    </>
  );
};
