import { Timestamp } from "firebase/firestore";
import { ReactElement } from "react";
import { ModelWithAction, TableColumn } from "./table.types";

export enum ContentType  {
    SEXTING = 'Sexting',
    UNLOCKED = 'Unlocked',
    CUSTOM = 'Custom', 
    DICK_RATE = 'Dick Rate',
    SUBSCRIPTION = 'Subscription',
    VIDEO_CALL = 'Video Call',
}

export enum StatusTransactionType {
    PAID = 'Paid',
    WITHDRAWN = 'Withdrawn',
    REFUNDED = 'Refunded',
    SCAM = 'Scam',
}

export enum UserType  {
    CLIENT = 'client',
    MODEL = 'model',
    OPERATOR = 'operator', 
}

export type UsersEnumMap = {
    [key in UserType]: NameIdType;
}

export enum WorkshiftEnum {
    DAY = 'day',
    NIGHT = 'night',
}

export type TransactionType = {
    id: string;
    date: Timestamp; 
    // UsersEnumMap
    workshift: WorkshiftEnum;
    bill: number;
    status: StatusTransactionType;
    type: ContentType | JSX.Element; // ReactElement for btn '+Add'
    content: string ; 
    description: string;
}

export type TransactionWithUsersMapType = TransactionType & UsersEnumMap & ModelWithAction;

export type ChatterShift = {
    chatter: NameIdType;
    total: number;
}

export type ClientStatisticType = {
    id: string;
    date: Timestamp; 
    total: number;
    night: ChatterShift;
    day: ChatterShift;
    average: number;
    max: number; 
}

export type ChatterProfitLevel = {
    chatter: NameIdType;
    total: number;
    level: number;
    profit: number;
}
export type ModelMonthColumnType = {
    id: string;
    date: Timestamp; 
    total: number;

    nightTotal: number;
    Nchatter: NameIdType;
    Nlevel: number;
    Nprofit: number;

    dayTotal: number;
    Dchatter: NameIdType;
    Dlevel: number;
    Dprofit: number;
}
// export type ModelStatisticType = {
//     id: string;
//     date: Timestamp; 
//     total: number;
//     night: ChatterProfitLevel;
//     day: ChatterProfitLevel;
// }

// LEVELS OF THE REVENUE SHARE
export type RevenueShareType = {
    revenue: number;
    share: number;
}
export type MoneyLevelByModelType = {
  model: NameIdType;
  date: Timestamp;
  level: {
    one: RevenueShareType;
    two: RevenueShareType;
    three: RevenueShareType;
  }
}


export interface TransColumnType extends TransactionType {
    // action: JSX.Element | any;
    action: any;
}


export interface NameIdType {
    id: string;
    name: string;
}

export type TransactionsType = TransactionType[];
