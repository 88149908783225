import React, { FC } from 'react';
import { Chip } from '@mui/material';
import { CellProps } from '@modules/table/types/table.types';

export const TypeCell:FC<CellProps<string>> = ({
  dataCell
}) => (
  <Chip 
    label={dataCell} 
    color="primary" 
    size="small" variant="outlined" 
  />
);