import { TransactionWithUsersMapType } from "@modules/table/types/transaction.types";
import { getDocsByDate } from "@modules/table/api/firebase/getDocsByDate";

export interface TransByOneDayParams {
    tableMode: string;
    id: string;
    year: string;
    month: string;
    day: string;
  }
  
export const getTransByOneDay = async(params: TransByOneDayParams) => {
  const {tableMode, id, year, month, day} = params;
  const yearNum = +('20'+year);
  const startDate = new Date(yearNum,+month-1, +day);
  const endDate = new Date(yearNum,+month-1, +day+1);
  const data = await getDocsByDate('transaction', tableMode, id, startDate, endDate);
  return data as TransactionWithUsersMapType[];
};