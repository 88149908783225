import React from 'react';

import { 
  doc, collection, query, where, getDocs, getDoc, 
} from 'firebase/firestore';

import { db } from '../../../../firebase/firebase';
import { UserType } from '@modules/table/types/transaction.types';
import { ProfileType } from '@modules/table/types/user.types';

export const getProfileData = async(
  userType: UserType, 
  id: string
) => {
  try {
    const docRef = doc(db, userType, id);
    const docSnap = await getDoc(docRef);

    if(docSnap.exists()) {
      const docData = docSnap.data();
      const profile:ProfileType = { 
        type: userType, 
        data: { 
          id: docSnap.id, 
          name: docData.name,  
          status: docData.status,  
        }
      };
      // console.log('profile', profile);
      return profile;
    }
    // return { error: "No such document!" };
    console.log('error:',  "No such document!" );
  } catch(err) {
    // return { error: err };
    console.log('error:', err );
  }
};
