import { GetTransRowCell } from '@modules/table/api/CellMap';
import { ModelWithId, TableCellMapProps, TableCellProps, TableRowProps } from '@modules/table/types/table.types';
import { TableCell, useTheme } from '@mui/material';
import React from 'react';

export const TransCellMap = <Model extends ModelWithId>({
  columns,
  item
}:TableCellMapProps<Model>): JSX.Element => {
  const theme = useTheme();
  return (
    <>
      {columns.map((column, index) => (
        <TableCell
          key={`${column.label}`} 
          sx={{ 
            textAlign: column.textAlign || "left",
            padding: theme.spacing(2,0.5),
            width: column.width, 
            minWidth: column.width, 
            borderBottom: '0.75rem solid #011028',
          }}
        > 
          <GetTransRowCell column={column} item={item} />
        </TableCell>
      ))}
    </>
  );
};