export enum actions {
    OPEN_ADD_FORM = "OPEN_ADD_FORM",
    CLOSE_ADD_FORM = "CLOSE_ADD_FORM",

    OPEN_DELETE_MODAL = "OPEN_DELETE_MODAL",
    CLOSE_DELETE_MODAL = "CLOSE_DELETE_MODAL",

    SET_EDIT_ID = "SET_EDIT_ID",
    UNDEFINE_EDIT_ID = "UNDEFINE_EDIT_ID",

    SET_COPY_ID = "SET_COPY_ID",

    SET_SUM_BILLS = "SET_SUM_BILLS",

    SET_PROFILE = "SET_PROFILE",
}

export type ActionType = {
  type: actions; 
  payload?: any;
}