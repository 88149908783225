import { IconButton } from '@mui/material';
import React, { FC } from 'react';
import ReplayIcon from '@mui/icons-material/Replay';
import { IconBtnProps } from '@modules/table/types/button.types';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';


export const ScammerIconBtn:FC<IconBtnProps & {state: boolean}> = ({
  onClick, 
  state = false
}) => {
  const color = state ? 'secondary' : 'primary';
  return (
    <IconButton 
      aria-label="save" 
      size="small" 
      color={color}
      onClick={onClick}
    >
      <FeedbackOutlinedIcon fontSize="small"/>
    </IconButton>
  );
};
