import React, { 
  createContext, 
  FC,  
  useContext, 
  useMemo, 
  useReducer, 
} from 'react';
import { ChildrenProps } from '@modules/table/types/table.types';
import { CommonStateType, InitialState } from './InitialState';
import { CommonTableContextDispatchType, InitialDispatch } from './InitialDispatch';
import { actions } from './actions';
import commonReducer from './reducer';


const CommonTableContextState = createContext<CommonStateType>(InitialState);
const CommonTableContextUpdater = createContext<CommonTableContextDispatchType>(InitialDispatch);

export const CommonTableContextProvider:FC<ChildrenProps> = ({ children }) => {
  const [state, dispatch] = useReducer(commonReducer, InitialState);
  // DO YOU NEED MEMO FOR  STATE? 
  // const stateValue = useMemo<CommonStateType>(
  //   () => state,
  //   [state]
  // );

  const api = useMemo<CommonTableContextDispatchType>(
    () => ({ 
      openAddForm: () =>
        dispatch({ type: actions.OPEN_ADD_FORM }),
      closeAddForm: () =>
        dispatch({ type: actions.CLOSE_ADD_FORM }),
      openDeleteModal: () =>
        dispatch({ type: actions.OPEN_DELETE_MODAL }),
      closeDeleteModal: () =>
        dispatch({ type: actions.CLOSE_DELETE_MODAL }),
      setEditID: (id) =>
        dispatch({ type: actions.SET_EDIT_ID, payload: id }),
      closeEditID: () =>
        dispatch({ type: actions.UNDEFINE_EDIT_ID }),
      setCopyID: (id) =>
        dispatch({ type: actions.SET_COPY_ID, payload: id }),
      setSumBills: (sum) => 
        dispatch({ type: actions.SET_SUM_BILLS, payload: sum }),
      setProfile: (profile) => 
        dispatch({ type: actions.SET_PROFILE, payload: profile }),
    }),
    [dispatch]
  );

  return (
    <CommonTableContextState.Provider value={{...state}}>
      <CommonTableContextUpdater.Provider value={api}>
        {/* <CommonTableContext.Provider value={contextValue}> */}
        {children}
        {/* </CommonTableContext.Provider> */}
      </CommonTableContextUpdater.Provider>
    </CommonTableContextState.Provider>
  );
};

export const useCommonTableContextState = () => {
  const context = useContext(CommonTableContextState);
  if (context === undefined) {
    throw new Error('useCommonTableContext must be used within CommonTableContextProvider');
  }
  return context;
};

export const useCommonTableContextUpdater = () => {
  const context = useContext(CommonTableContextUpdater);
  if (context === undefined) {
    throw new Error('useCommonTableContext must be used within CommonTableContextProvider');
  }
  return context;
};
