
import React from 'react';

import { doc, deleteDoc } from "firebase/firestore";

import { db } from '../../../../firebase/firebase';

// Probably all Adding requests
export const delDoc = async(
  collection: string, id: string, 
) => {

  try {
    const docRef = doc(db, collection, id);
    await deleteDoc(docRef);

    return { data: `Delete doc ${id} succesfull.`};
  } catch(err) {
    return { error: err };
  }
};
