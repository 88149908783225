
import { WorkshiftEnum } from '@modules/table/types/transaction.types';
import { Box } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BtnStartShift } from '../../Buttons/BtnStartShift';

export const BtnShiftPanel = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const startShiftHandler = (e:any, shift: WorkshiftEnum) => {
    e.preventDefault();
    const date = new Date (Date.now());
    const day = date.getDate();
    const link = pathname +'/'+ day +'/'+ shift;
    navigate(link);
  };

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        width: '100%', 
        justifyContent: 'center',
        // flexGrow: 1,
      }}
    >
      <Box sx={{ marginLeft: '2rem', width: '8rem', }}>
        <BtnStartShift 
          shift={WorkshiftEnum.DAY} 
          onClick={(e:any) => startShiftHandler(e, WorkshiftEnum.DAY)}
        />
      </Box>
      <Box sx={{ marginLeft: '0.5rem', width: '8rem', }}>
        <BtnStartShift 
          shift={WorkshiftEnum.NIGHT} 
          onClick={(e:any) => startShiftHandler(e, WorkshiftEnum.NIGHT)}
        />
      </Box>
    </Box>
  );
};