import { getDateNow } from "@modules/form/utils/getDateNow";
import { 
  ContentType, 
  StatusTransactionType, 
  TransactionWithUsersMapType, 
  WorkshiftEnum
} from "@modules/table/types/transaction.types";

export const InitialState:TransactionWithUsersMapType = {
  id: '',
  date: getDateNow(),
  model: {
    id: '',
    name: ''
  },
  client: {
    id: '',
    name: ''
  },
  operator: {
    id: '',
    name: ''
  },
  workshift: WorkshiftEnum.NIGHT,
  bill: 0,
  status: StatusTransactionType.PAID,
  type: ContentType.UNLOCKED,
  content: '',
  description: '',
};
