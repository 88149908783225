import React, { FC, useContext } from 'react';
import { BtnMiddle } from '../BtnMiddle';
import { 
  AddCircle as AddCircleIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import { addTransaction } from '@modules/table/api/firebase/addTransaction';

export interface BtnAddSaveProps {
    state: boolean;
    toggleAction: {
      on: () => void,
      off: () => void,
    }
}

const BtnAddSave:FC<BtnAddSaveProps> = ({
  state,
  toggleAction, 
}) => {
  // const { onSave } = useContext(FormContext);
  return (
    <BtnMiddle 
      width='100%'
      icon={ state ? <SaveIcon /> : <AddCircleIcon />} 
      title={ state ? 'SAVE' : 'ADD'} 
      onClick={
        state ? (e:any) => { 
          // onSave();
          // call from reducer
          toggleAction.off(); 
          // await addTransaction(newTransaction);
        } : 
          () => toggleAction.on()
      }
    />
  );
};
export default BtnAddSave;