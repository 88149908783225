import { FormControl, Input, InputAdornment, InputLabel } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { InputIconProps } from '@modules/form/types/form.types';

export const InputIconSymbol:FC<InputIconProps> = ({
  icon,
  symbol,
  id,
  value,
  label,
  handleChange
}) => {
  const [localValue, setLocalValue] = useState<number>(0);
  useEffect(() => {
    if(value !== 0) {
      setLocalValue(value);
    }
  }, []);

  return (
    <FormControl fullWidth sx={{ m: 1, paddingLeft: '2rem' }} variant="standard">
      <InputLabel 
        htmlFor="standard-adornment-amount"
        sx={{paddingLeft: '2.5rem' }}
      >{label}</InputLabel>
      <Input
        id="standard-adornment-amount"
        value={localValue}
        onChange={(e:any) => {
          handleChange(id, e.target.value); 
          setLocalValue(e.target.value);
        }}
        startAdornment={ symbol ?
          <InputAdornment position="start">{symbol}</InputAdornment> 
          : <></>
        }
      />
    </FormControl>
  );};
