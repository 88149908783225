import {  
  StatusTransactionType,
  TransactionWithUsersMapType,
  UserType 
} from "@modules/table/types/transaction.types"; 
import { ActionBtnInRow } from "@modules/table/components/Buttons/ActionBtnInRow";
import { 
  BillCell,
  DateCell,
  TypeCell,
  LinkCell,
  DateEnumType
} from "@modules/table/components/Cells";
import { CellComponentMapProps, ModelWithId, TableCellProps } from "@modules/table/types/table.types";
import { ProfileShortType } from "@modules/table/types/user.types";

import {
  Replay as ReplayIcon,
  FeedbackOutlined as FeedbackOutlinedIcon,
} from '@mui/icons-material';
import { useState } from "react";

// GetCellComponentBy
const CellComponentMap: 
CellComponentMapProps<Partial<TransactionWithUsersMapType>> = {
  date: DateCell,
  bill: BillCell,
  type: TypeCell,
};

export const GetTransRowCell = <Model extends ModelWithId>({
  column,
  item,
}:TableCellProps<Model>) => {
  // Body Table
  const itemCopy = item as unknown as TransactionWithUsersMapType;
  if (column.value === 'action') {
    if(itemCopy.status === StatusTransactionType.REFUNDED) {
      return (
        <div style={{ margin: '0 0.5rem'}}>
          <ReplayIcon fontSize="small" color="secondary"/>
        </div>
      );
    }
    if(itemCopy.status === StatusTransactionType.SCAM) {
      return (
        <div style={{ margin: '0 0.5rem'}}>
          <FeedbackOutlinedIcon fontSize="small" color="secondary"/>
        </div>
      );
    }
    return <ActionBtnInRow id={item.id} />;
  }
  // if (column.value in Object(CellComponentMap).keys()) {
  //   const Component = CellComponentMap[column.value];
  //   return <Component dataCell={item[column.value]}/>;
  // }

  if (column.value === 'date') {
    const dateObj = {
      date: +item[column.value],
      mode: DateEnumType.DATE,
    };
    return (
      <div style={{ margin: '0 0 0 1rem'}}>
        <DateCell dataCell={dateObj} />
      </div>
    );
  }

  // if (column.value in UserType) DOESN'T WORK AND NO RETURN 
  if( Object.values(UserType).includes(column.value as UserType) ) {
    const linkObj = {
      type: column.value as UserType,
      // hardcode
      data: item[column.value] as unknown as ProfileShortType,
    };
    return <LinkCell dataCell={linkObj} />;
  }

  if (['bill', 'total', 'average', 'max'].includes(column.value as string)) {
    let statusBill: boolean = true;
    if([StatusTransactionType.REFUNDED, StatusTransactionType.SCAM].includes(itemCopy.status)) 
      statusBill = false;
    return (
      <BillCell 
        dataCell={{
          bill: +item[column.value],
          status: statusBill,
        }} 
      />
    );
  }
  if (column.value === 'type') {
    return <TypeCell dataCell={String(item[column.value])} /> ;
  }
  if (['content', 'description'].includes(column.value as string)) {
    return (
      <>{item[column.value]}</>
    );
  }
  return null;
};