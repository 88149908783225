import React from 'react';

import { 
  doc, collection, query, where, getDocs, writeBatch, 
} from 'firebase/firestore';

import { db } from '../../../../firebase/firebase';
import { StatusTransactionType } from '@modules/table/types/transaction.types';
import { ClientStatusType } from '@modules/table/types/user.types';

export const setStatus = async(
  id: string, status: ClientStatusType,
) => {
  try {
    const batch = writeBatch(db);
    const docClient = doc(db, "client", id);
    batch.update(docClient, {"status": status});

    const StatusTransaction = (status === ClientStatusType.SCAMMER) ? 
      StatusTransactionType.SCAM : StatusTransactionType.PAID;

    const q = query(collection(db, "transaction"), where(`client.id`, "==", id));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((document) => {
      const docRef = doc(db, "transaction", document.id);
      batch.update(docRef, {"status": StatusTransaction});
    });
    await batch.commit();

    return { data: 'Refunded all scam transactions'};
  } catch(err) {
    return { error: err };
  }
};
