import { TableRow as MuiTableRow, useTheme } from "@mui/material";
import { ModelWithId, TableRowProps } from "@modules/table/types/table.types";
import { DayCellMap } from "./DayCellMap";
import { ClientStatisticType} from "@modules/table/types/transaction.types";
import { useLocation, useNavigate } from "react-router-dom";


//TableRow universal, create TransactionRow  DayStatisticRow (probably the same for model/chatter/client)
export const DayRow = <Model extends ModelWithId>({
  columns,
  item,
}:TableRowProps<Model>): JSX.Element => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  let backgroundColor = "background.paper";
  let backgroundHover =  "background.default";

  const itemCopy = item as unknown as ClientStatisticType;
  const date = itemCopy['date'].toDate();
  const Link = `${pathname}/${date.getDate()}`;


  return (
    <MuiTableRow 
      // onClick={() => navigate(Link)}
      sx={{
        // cursor: 'pointer',
        padding: theme.spacing(4,4),
        backgroundColor: backgroundColor,
        "&:hover": {
          backgroundColor: backgroundHover
        }
      }}
    >
      <DayCellMap columns={columns} item={item} link={Link}/>
    </MuiTableRow>
  );
};
