import { ProfileType } from "@modules/table/types/user.types";

export interface CommonTableContextDispatchType {
    openAddForm: () => void;
    closeAddForm: () => void;

    openDeleteModal: () => void;
    closeDeleteModal: () => void;

    setEditID: (id: string) => void;
    closeEditID: () => void;

    setCopyID: (id: string) => void;

    setSumBills: (sum: number) => void;

    setProfile: (profile: ProfileType) => void;
}

export const InitialDispatch:CommonTableContextDispatchType = {
  openAddForm: () => null,
  closeAddForm: () => null,

  openDeleteModal: () => null,
  closeDeleteModal: () => null,

  setEditID: () => null,
  closeEditID: () => null,

  setCopyID: () => null,

  setSumBills: () => null,

  setProfile: () => null,
};