import { TransactionWithUsersMapType } from "@modules/table/types/transaction.types";
import { calcTotalAverageMax } from "@modules/table/utils/calcTotalAverageMax";

export const calculateDayNums = <Type>(items: Type[]) => {
  // 1) Type
  const itemsModel = items as unknown as TransactionWithUsersMapType[];
  // 2) Put all Bills to seperate Array
  const itemsBills: number[] = itemsModel ? itemsModel.map(
    (trans) => ( !['Refunded', 'Scam'].includes(trans.status) ) ? trans.bill : 0) 
    : [0]; //undefined or just 0?
   
  return calcTotalAverageMax(itemsBills);
};