import { TableColumn } from '@modules/table/types/table.types';
import { Skeleton, TableCell, TableRow as MuiTableRow, useTheme} from '@mui/material';
import React, { FC } from 'react';

export interface SkeletonRowMapProps {
    length: number;
    columns: TableColumn<any>[];
}

export const SkeletonRowMap:FC<SkeletonRowMapProps> = ({
  length, 
  columns
}) => {
  const theme = useTheme();
  const skeletonArr = Array.from({length: length});

  return (
    <>
      {skeletonArr.map(skeleton => (
        <MuiTableRow 
          key={`skeleton-${skeleton}`}
          sx={{
            padding: theme.spacing(4,4),
            // backgroundColor: backgroundColor,
          }}
        >
          <>
            {columns.map((column, index) => (
              <TableCell
                key={`$${column.label}-${index}`} 
                sx={{ 
                  textAlign: column.textAlign || "left",
                  padding: theme.spacing(2,1),
                  width: column.width, 
                  minWidth: column.width, 
                }}
              > 
                <Skeleton variant="rounded" animation="wave" width={'100%'} height={20} />
              </TableCell>
            ))}
          </>
        </MuiTableRow>
      ))}
    </>
  );
};
