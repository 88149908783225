import { NameOptionType } from "@modules/form/types/form.types";
import { TransactionWithUsersMapType } from "@modules/table/types/transaction.types";

export enum actions {
    ADD_FIELD = "ADD_FIELD",
    SET_EDIT_DATA = "SET_EDIT_DATA",
    INITIAL_DATA = "INITIAL_DATA",
}

export type PayloadType = {
    id: keyof TransactionWithUsersMapType;
    value: any;
}

export type ActionType = {
    type: actions; 
    // payload: PayloadType | Partial<TransactionWithUsersMapType>;
    payload?: any;
}