
import React, { useEffect, useState } from 'react';
import { ModelWithId, TableFetchParams, TableRowMapProps } from '@modules/table/types/table.types';
import { DayRow } from '@modules/table/components/TableBody/DayRowMap/DayRow';
import { useParams } from 'react-router-dom';
import { getMonthChattersStatistic, getMonthModelStatistic, getMonthStatistic } from '@modules/table/api/monthData';
import { UserType } from '@modules/table/types/user.types';
import { useHeaderContextDispatch, useHeaderContextState } from '@modules/table/context/HeaderContext/context';
import { ConstructionOutlined } from '@mui/icons-material';
import { getMonthClientStatistic } from '@modules/table/api/monthData/getMonthClientStatistic';
import { Skeleton, TableCell, TableRow as MuiTableRow, useTheme } from '@mui/material';
import { SkeletonRowMap } from '../SkeletonRowMap';


export const DayRowMap = <Model extends ModelWithId>({
  columns
}:TableRowMapProps<Model>) => {
  const theme = useTheme();
  const { tableMode, id, year, month } = useParams<TableFetchParams>();
  const [items, setItems] = useState<Model[]  | undefined>(undefined);
  const { setMonthStatistic } = useHeaderContextDispatch();
  // if(!items) return null;

  useEffect(()=> {
    const fetchData = async() => {
      if (tableMode && id && year && month) {
        if(tableMode === UserType.CLIENT) {
          // const arr = await getMonthStatistic<Model>({tableMode, id, year, month});
          // setItems(arr);
          const {arrDayRows, totalRevenue, averageDay, maxDay } = 
            await getMonthClientStatistic<Model>({tableMode, id, year, month});
          setMonthStatistic({totalRevenue, averageDay, maxDay});
          setItems(arrDayRows);
        }
        if(tableMode === UserType.MODEL) {
          const {arrDayRows, totalRevenue, averageDay, maxDay} = 
            await getMonthModelStatistic<Model>({tableMode, id, year, month});
          setMonthStatistic({totalRevenue, averageDay, maxDay});
          setItems(arrDayRows);
        }
        if(tableMode === UserType.OPERATOR ) {
          const { arrDayRows, totalMonthProfit } = 
            await getMonthChattersStatistic<Model>({tableMode, id, year, month});
          setItems(arrDayRows);
          setMonthStatistic({totalRevenue: totalMonthProfit, averageDay: 0, maxDay:0});
        }
      }
    };
    fetchData();
  }, [tableMode, id, year, month]);

  return (
    <>
      {items ? (
        items.map((item: Model) => (<DayRow key={item.id} item={item} columns={columns} />)) 
      ) : (
        <SkeletonRowMap length={10} columns={columns} />
      )}
    </>
  );
};
