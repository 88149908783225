import React, { FC } from 'react';
import { CellProps } from '@modules/table/types/table.types';
import { ProfileLinkType } from '@modules/table/types/user.types';
import { Link, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material';

export const LinkCell:FC<CellProps<ProfileLinkType>> = ({
  dataCell
}) => {
  const theme = useTheme();
  const { year, month } = useParams();
  return (
    <p>
      <Link 
        onClick={e => e.stopPropagation()} 
        to={`/table/${dataCell.type}/${dataCell.data.id}/${year}/${month}`}
        style={{ marginRight: '0.5rem'}}
      >
        {dataCell.data.name}
      </Link>
      {dataCell.data.link ? 
        (<a 
          onClick={e => e.stopPropagation()} 
          href={`https://onlyfans.com/${dataCell.data.link}`}
          target="_blank" rel="noopener noreferrer"
          style={{ color: theme.palette.primary.main}}
        >
      @{dataCell.data.link}
        </a>) 
        : null
      }
    </p>
  );
};