import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { 
  useCommonTableContextState, 
  useCommonTableContextUpdater 
} from '@modules/table/context/CommonTableContext/context';
import { delDoc } from '@modules/table/api/firebase/delDoc';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export interface ModalAlertProps {
  action?: any
}
export const ModalAlert:React.FC<ModalAlertProps> = ({
  action
}) => {
  const { stateDeleteModal, editID } = useCommonTableContextState();
  const { closeDeleteModal, closeEditID } = useCommonTableContextUpdater();

  const handleClose = () => {
    closeDeleteModal();
    closeEditID();
  };

  const handleDelete = async() => {
    if(editID) {
      await delDoc('transaction', editID);
    }
    handleClose();
  };


  return (
    <div>
      <Dialog
        open={stateDeleteModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle>{"Are you sure delete this money data?"}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure delete this money data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete}>Delete</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
