import React, { 
  createContext, 
  FC,  
  useContext, 
  useMemo, 
  useReducer, 
} from 'react';
import { ChildrenProps } from '@modules/table/types/table.types';
import { basicMonthStatistic, InitialState, InitialStateType } from './InitialState';
import { 
  HeaderContextDispatchType, 
  InitialDispatch 
} from './InitialDispatch';
import { actions } from './actions';
import Reducer from './reducer';


const HeaderContextState = createContext<InitialStateType>(InitialState);
const HeaderContextDispatch = createContext<HeaderContextDispatchType>(InitialDispatch);

export const HeaderContextProvider:FC<ChildrenProps> = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  // DO YOU NEED MEMO FOR  STATE? 
  // const stateValue = useMemo<CommonStateType>(
  //   () => state,
  //   [state]
  // );

  const api = useMemo<HeaderContextDispatchType>(
    () => ({ 
      setMonthStatistic: (stat: basicMonthStatistic) => {
        dispatch({ type: actions.SET_MONTH_STATISTIC, payload: stat});
      }
    }),
    [dispatch]
  );

  return (
    <HeaderContextState.Provider value={{...state}}>
      <HeaderContextDispatch.Provider value={api}>
        {/* <CommonTableContext.Provider value={contextValue}> */}
        {children}
        {/* </CommonTableContext.Provider> */}
      </HeaderContextDispatch.Provider>
    </HeaderContextState.Provider>
  );
};

export const useHeaderContextState = () => {
  const context = useContext(HeaderContextState);
  if (context === undefined) {
    throw new Error('useHeaderContextState must be used within useHeaderContextProvider');
  }
  return context;
};

export const useHeaderContextDispatch = () => {
  const context = useContext(HeaderContextDispatch);
  if (context === undefined) {
    throw new Error('HeaderContextDispatch must be used within HeaderContextDispatchProvider');
  }
  return context;
};
