
import { 
  BillCell,
  DateCell,
  DateEnumType,
  LinkCell,
  LinkWrapperCell,
  TypeCell
} from "@modules/table/components/Cells";
import { UserType } from "@modules/table/types/user.types";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DayCellProps, ModelWithId, TableCellProps } from "../../types/table.types";
import { ChatterShift, ClientStatisticType, NameIdType, WorkshiftEnum } from "../../types/transaction.types";
import { ChatterShiftModelsType, OneModelRevenueType } from "../monthData";
import { summarize } from "../summarize";



export const GetChatterDayRowCell = <Model extends ModelWithId>({
  column,
  item,
  link
}:DayCellProps<Model>) => {
  const itemtype = item as unknown as ChatterShiftModelsType;
  const dayModelArr = itemtype['dayModels'];
  const nightModelArr = itemtype['nightModels'];
  const indexStr = String(column.value).slice(1,) as keyof OneModelRevenueType; 


  if (column.value === 'date') {
    const dateObj = {
      date: +item[column.value],
      mode: DateEnumType.DAY,
    };
    return (
      <div style={{ margin: '0 0 0 1rem'}}>
        <DateCell dataCell={dateObj} />
      </div>
    );
  }

  if (['DayTotal', 'NightTotal'].includes(column.value as string)) {
    let totalProfit = 0;
    if(column.value === 'DayTotal') {
      totalProfit = summarize(dayModelArr ? dayModelArr.map(item => item.profit): undefined);
    }
    if(column.value === 'NightTotal') {
      totalProfit = summarize(nightModelArr ? nightModelArr.map(item => item.profit): undefined);
    }
    if (totalProfit === 0) return null;

    let statusBill: boolean = true;
    const Cell = (
      <BillCell 
        dataCell={{
          bill: totalProfit,
          status: statusBill,
        }} 
      />
    );
    if(column.value === 'DayTotal') return LinkWrapperCell(link, Cell, WorkshiftEnum.DAY);
    if(column.value === 'NightTotal') return LinkWrapperCell(link, Cell, WorkshiftEnum.NIGHT);
    return null;
  }


  if (['Dmodel'].includes(column.value as string)) {
    if(dayModelArr.length > 0) { 
      return (
        <>
          {dayModelArr.map((item,index) => {
            const nameIdData = item['model'] as unknown as NameIdType;
            return (
              <LinkCell 
                key={`LinkCell-${nameIdData.id}`}
                dataCell={{
                  type: UserType.MODEL,
                  data: nameIdData,
                }} />
            );
          })}
        </>
      );
    } else return null;
  }
  if (['Nmodel'].includes(column.value as string)) {
    if(nightModelArr.length > 0) {
      return (
        <>
          {nightModelArr.map((item,index) => {
            const nameIdData = item['model'] as unknown as NameIdType;
            return (
              <LinkCell 
                key={`LinkCell-${nameIdData.id}`}
                dataCell={{
                  type: UserType.MODEL,
                  data: nameIdData,
                }} />
            );
          })}
        </>
      );
    } else return null;
  }

  if (['Drevenue','Dprofit'].includes(column.value as string)) {
    // const color = (column.value === 'Dprofit') ? 'primary' : 'text';
    const color = "text";
    if(dayModelArr.length > 0) {
      return (
        <>
          {dayModelArr.map((item,index) => 
            <Typography 
              key={index}
              color={color} 
            >
              ${item[indexStr].toString()}
            </Typography>
          )}
        </>
      );
    } else return null;
  }
  if (['Nrevenue','Nprofit'].includes(column.value as string)) {
    // const color = (column.value === 'Dprofit') ? 'primary' : 'text';
    const color = "text";
    if(nightModelArr.length > 0) {
      return (
        <>
          {nightModelArr.map((item,index) => 
            <Typography 
              key={index}
              color={color} 
            >
              ${item[indexStr].toString()}
            </Typography>
          )}
        </>
      );
    } else return null;
  }

  if (['Dlevel'].includes(column.value as string)) {
    if(dayModelArr.length > 0) {
      return (
        <>
          {dayModelArr.map((item, index) => 
            <TypeCell 
              key={index}
              dataCell={String(item[indexStr])} 
            />
          )}
        </>
      );
    } else return null;
  }
  if (['Nlevel'].includes(column.value as string)) {
    if(nightModelArr.length > 0) {
      return (
        <>
          {nightModelArr.map((item, index) => 
            <TypeCell 
              key={index}
              dataCell={String(item[indexStr])} 
            />
          )}
        </>
      );
    } else return null;
  }

  return null;
};