import React from 'react';
import { 
  Routes,
  Route
} from 'react-router-dom';

import { Table } from '../Table';

const TableRouter = () => (
  // Tao: all logic in Router ? OR compound Component Biz and Router?
  <>
    <Routes>
      {/* '/model' -see all models by sum  */}
      <Route path=':tableMode' element={<Table />}>
        <Route path=':id' element={<Table />}> 
          <Route path=':year' element={<Table />}> 
            <Route path=':month' element={<Table />}> 
              <Route path=':day' element={<Table />}> 
                <Route path=':shift' element={<Table />}/> 
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  </>
);

export default TableRouter;