import { FC, useContext, useEffect, useState } from "react";
import { BillCell } from "@modules/table/components/Cells/BillCell";
import {  TableFooterCellProps } from "@modules/table/types/table.types";
import { BtnMiddle } from "@modules/table/components/Buttons/BtnMiddle";
import BtnAddSave from "@modules/table/components/Buttons/BtnAddSave/BtnAddSave";
import { 
  useCommonTableContextState, useCommonTableContextUpdater 
} from "@modules/table/context/CommonTableContext/context";
import { addTransaction } from "../firebase";
import { useFormContextState } from "@modules/form/context/Form/context";

// GetCellComponentBy
export const GetFooterComponents = <Model extends {}>({
  column
}:TableFooterCellProps<Model>) => {
  const dataForm = useFormContextState();
  const { stateAddForm, sumBills } = useCommonTableContextState();
  const { openAddForm, closeAddForm } = useCommonTableContextUpdater();

  const saveHandler = async() => {
    await addTransaction(dataForm);
    closeAddForm();
  };

  if (column.value === 'action') {
    return (
      <div style={{
        width: '2rem'
      }}/>
    );
  }

  // Footer Table
  if (column.value === 'bill' && sumBills) {
    return (
      <div style={{ paddingLeft: '2rem'}}>
        <BillCell dataCell={{ bill: sumBills, status: true}} />
      </div>
    )
    ;
  }
  if (column.value === 'type') {
    return (
      <BtnAddSave 
        state={stateAddForm} 
        toggleAction={{
          on: openAddForm,
          off: saveHandler,
        }}
        // newTransaction={trans}
      />
    );
  }
  if (column.value === 'content') {
    if(!stateAddForm) return null;
    if(stateAddForm) return (
      <BtnMiddle 
        variant='outlined'
        title='CANCEL'
        onClick={() => closeAddForm()}
      />
    );
  }
  return null;
};