import { Timestamp } from "firebase/firestore";

export const getDayData = (
  data: any[], yearNum: number, 
  month:number, day:number
) => {
  const startDate = Timestamp.fromDate(new Date(yearNum, +month-1, day));
  const endDate = Timestamp.fromDate(new Date(yearNum, +month-1, day+1));
  const oneDayTrans = data.filter(
    item => (item.date >= startDate) && (item.date < endDate)
  );
  return oneDayTrans;
};