import { ProfileType } from "@modules/table/types/user.types";

export interface CommonStateType {
    stateAddForm: boolean;
    stateDeleteModal: boolean;
    editID: string | undefined;
    copyID: string | undefined;
    sumBills: number;

    profile: ProfileType | undefined;
}

export const InitialState:CommonStateType = {
  stateAddForm: false,
  stateDeleteModal: false,
  editID: undefined,
  copyID: undefined,
  sumBills: 0,

  profile: undefined,
};

