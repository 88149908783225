import { Button } from '@mui/material';
import React, { FC, ReactElement } from 'react';

export interface BtnProps {
    variant?: "text" | "contained" | "outlined";
    color?: 'primary' | 'secondary';
    width?: string;
    icon?: ReactElement;
    title: string;
    onClick: any;
}

export const BtnMiddle:FC<BtnProps> = ({
  variant,
  color,
  width,
  icon,
  title,
  onClick,
}) => (
  <Button 
    variant={variant ? variant : "contained" }
    color={color ? color : "primary" }
    startIcon={icon}
    sx={{
      width: width,
      borderRadius: '2rem',
    }}
    onClick={onClick}
  >
    {title} 
  </Button>
);