import React from 'react';

import { 
  doc, collection, query, where, getDocs, addDoc, 
} from 'firebase/firestore';

import { db } from '../../../../firebase/firebase';
import { TransactionType } from '@modules/table/types/transaction.types';

// Probably all Adding requests
export const addTransaction = async(
  dataTrans: TransactionType, 
) => {
  if(!dataTrans) return { error: "Data from Form doesn't provide" };
  try {
        
    const docRef = await addDoc(collection(db, "transaction"), dataTrans);
    console.log("Document written with ID: ", docRef.id);

    return { docId: docRef.id };
  } catch(err) {
    return { error: err };
  }
};
