import { useCommonTableContextState, useCommonTableContextUpdater } from '@modules/table/context/CommonTableContext/context';
import { Box, TableRow as MuiTableRow, TableCell } from '@mui/material';
import ShopOutlinedIcon from '@mui/icons-material/ShopOutlined';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import React from 'react';

export const BtnAddRowSell = () => { 

  const { stateAddForm, sumBills } = useCommonTableContextState();
  const { openAddForm, closeAddForm } = useCommonTableContextUpdater();
  
  // const saveHandler = async() => {
  //   await addTransaction(dataForm);
  //   closeAddForm();
  // };

  return (

    <MuiTableRow 
      sx={{
        height: '3.5rem',
        background: '#011028',
      }}
    >
      <TableCell 
        colSpan={8}
        sx={{   
          border: '1px dashed #01c6ee68',
          borderRadius: '0.25rem',
          "&:hover": {
            border: '1px dashed #01c6ee',
          },
          padding: 0,
        }}
      >
        <Box 
          component='button'
          sx={{
            width: '100%',
            height: '3.5rem',
            margin: '0',
            background: 'none',
            border: 'none',

            cursor: 'pointer',
            diplay: 'flex',
            textAlign: 'center',
            alignItems: 'center',
            color: 'primary.main',
            fontSize: '0.9rem',
            fontWeight: '700',
          }}
          onClick={openAddForm}
        >
        {/* +  <VideoLibraryOutlinedIcon sx={{ marginBottom: '-0.3rem'}}/>  Add Sell  */}
        + Add Sell 
        </Box>
      </TableCell>
    </MuiTableRow>
  );
};
