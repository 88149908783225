import { FC, useEffect, useState } from "react";
import {
  FormControl, 
  TextField, 
} from "@mui/material";
import { InputProps } from "@modules/form/types/form.types";

export const InputText:FC<InputProps> = ({
  id,
  label,
  value,
  handleChange
}) => {
  const [localValue, setLocalValue] = useState<string>('');

  useEffect(() => {
    if(value) {
      setLocalValue(value);
    }
  }, []);

  return (
    <FormControl variant="outlined" sx={{width: '100%'}}>
      <TextField
        required
        id="standard-read-only-input"
        label={label}
        value={localValue}
        variant="standard"
        onChange={(e) => {
          handleChange(id, e.target.value); 
          setLocalValue(e.target.value);
        }}
        multiline
        maxRows={2}
      />
    </FormControl>
  );};
